import { Widget } from '@typeform/embed-react';
import { useEffect, useState } from 'react';

const TypeFormEmbeded = () => {
  const [shareId, setShareId] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    function uuid() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    }
    setShareId(uuid());
    const storedUser = JSON.parse(localStorage.getItem('user'))
    const storedUserId = storedUser?.id;
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);


  function submitForm({ formId, responseId }) {
    console.log("Submitting FORM!!!")
    console.log(formId, responseId)
    checkCompletion();
  }
  async function checkCompletion() {
    var response = await fetch(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF/share/${shareId}`);
    if (response.status === 200) {
      const storedUser = JSON.parse(localStorage.getItem('user'))
      const storedUserId = storedUser?.id;
      if (!storedUserId) {
        var storedTests = sessionStorage.getItem('shareIds') || '';
        storedTests = storedTests.split(',')
        storedTests.push(shareId);
        sessionStorage.setItem('shareIds', storedTests.join(','));
      }
      location.href = '/share?id=' + shareId;
    } else {
      setTimeout(checkCompletion, 1000);
    }
  }
  return <Widget id="AAeIQar4" className="h-screen" hidden={{ share_id: shareId, user_id: userId  }} onSubmit={(formId, responseId) => submitForm(formId, responseId)} />;
};

export default TypeFormEmbeded;
