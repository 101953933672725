import { Fragment, useEffect, useState } from 'react'

import { useModal } from '../contexts/ModalContext';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';

const Header = (props) => {
  const { showModal } = useModal();
  const { isUserLoggedIn, logout, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const navItems = [
    { id: 1, text: 'Methodology', active: "methodology", route: "/methodology" },
    { id: 3, text: 'Framework', active: "framework", route: "/framework" },
    { id: 2, text: 'Personality Types', active: "personality-types", route: "/personality-types" },
    // { id: 3, text: 'Blogs', active: "blog", route: "/blog" },
    // { id: 4, text: 'About us', active: "about-us", route: "/about-us" },
  ];

  const handleMenuOpen = (value) => {
    setIsMenuOpen(value)
  }
  const handleLogout = async (e) => {
    e.preventDefault();
    setLoading(true);
    await delay(1000);
    await logout();
    setLoading(false);
  };
  const handleLoginButtonClick = () => {
    showModal();
    handleMenuOpen(false);
  };
  const fetchData = async () => {
    if (isUserLoggedIn) {
      try {
        const response = await axios.get('https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF:v1/test'); // Replace with your actual API endpoint
        console.log(response)
      } catch (error) {
        console.log(error.response.status)
        setLoading(true);
        await delay(1000);
        await logout();
        setLoading(false);
        console.error('Error fetching test data:', error);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const NavLink = ({ to, children, highlight }) => {
    const baseClasses = "m-auto font-grifter text-sm lg:text-lg hover:text-[#A588FF]";
    const highlightClass = highlight ? "text-[#A588FF] pb-2" : "";
    const classes = `${baseClasses} ${highlightClass}`;

    return (
      <Link to={to} className={classes}>
        {children}
      </Link>
    );
  };

  return (
    <div className='relative'>
      {/* <img
        loading="lazy"
        className="absolute h-auto w-full opacity-10 fade-image-radial"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/afaf2696095ee86841aa70eefaede43a777e2ddf698b0e52d2f251f6443444c3?apiKey=2d1d137607f749e6b268ecb1ba4f2080&"
      /> */}
      <div className="items-center text-white font-bold w-full sm:px-0 md:px-0 z-10">
        <div className="relative md:w-4/5 justify-between flex p-4 md:mx-auto text-base">
          <Link to="/" className='z-50'><img
            loading="lazy"
            className="aspect-[1.5] h-24 w-24"
            src="/tslogov2.png"
          /></Link>
          <div className="z-50 hidden lg:flex flex-wrap items-center justify-between gap-8 m-auto font-[Poppins]">
            {navItems.map(item => (
              <NavLink to={item.route} highlight={props.active === item.active}>{item.text}</NavLink>
            ))}
            {isUserLoggedIn ?
              <NavLink to="/dashboard" highlight={props.active === "dashboard"}>Dashboard</NavLink>
              :
              null
            }
            {!isUserLoggedIn ?
              <button className="hover:scale-105 rounded-full bg-white hover:bg-opacity-80 text-black py-2 px-6 text-xs font-bold md:py-3 md:px-12 md:text-md" onClick={showModal}>
                Login
              </button>
              :
              <button className="hover:scale-105 rounded-full bg-white hover:bg-opacity-80 text-black py-2 px-6 text-xs font-bold md:py-3 md:px-12 md:text-md" onClick={handleLogout}>
                {loading ? "Loading..." : "Log out"}
              </button>
            }
            <a href="/test" className="hover:scale-105 rounded-full bg-[#7035de] hover:bg-opacity-80 text-white py-2 px-6 text-xs font-bold md:py-3 md:px-12 md:text-md">Take Test</a>
          </div>
          <button className='bg-white my-5 border px-3 rounded-lg z-40 block lg:hidden cursor-pointer' onClick={() => handleMenuOpen(true)}><AiOutlineMenu color='black' size={20} /></button>




          <Transition.Root className="z-40" show={isMenuOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={() => handleMenuOpen(false)}>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-0"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-0"
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className='bg-white rounded-l-md py-10 absolute z-50 h-screen md:hidden right-0 top-0 w-[60%] border-r-gray-900 ease-in-out duration-500'>
                    <AiOutlineClose className='border rounded-lg float-right m-5 -mt-5' size={20} onClick={() => handleMenuOpen(false)} color='black' />
                    <ul className=''>
                      {navItems.map(item => (
                        <li
                          key={item.id}
                          className='text-black py-4 px-4 mx-2 duration-300  cursor-pointer  border-b-2'>
                          <NavLink to={item.route} highlight={props.active === item.active}>{item.text}</NavLink>
                        </li>
                      ))}
                      {isUserLoggedIn ?
                        <li
                          key={"11"}
                          className='text-black py-4 px-4 mx-2   duration-300cursor-pointer  border-b-2'>
                          <NavLink to="/dashboard" highlight={props.active === "dashboard"}>Dashboard</NavLink>
                        </li>
                        :
                        null
                      }

                    </ul>
                    {!isUserLoggedIn ?
                      <div
                        className='text-black py-4 px-4 border-b-2 w-full mx-auto rounded-md duration-300 hover:text-white cursor-pointer border-gray-600'>
                        <button className="hover:scale-105 w-full rounded-full bg-white hover:bg-opacity-80 text-black py-2 px-6 text-sm font-bold md:py-3 md:px-12 md:text-lg" onClick={handleLoginButtonClick}>
                          Login
                        </button>
                      </div>
                      :
                      <div
                        className='text-center items-center text-black py-4 px-4 border-b-2  mx-auto rounded-md duration-300 hover:text-white cursor-pointer border-gray-600'>
                        <button className="hover:scale-105 rounded-full bg-white hover:bg-opacity-80 text-black py-2 px-6 text-sm font-bold md:py-3 md:px-12 md:text-lg" onClick={handleLogout}>
                          {loading ? "Loading..." : "Log out"}
                        </button>
                      </div>
                    }
                    <div className='flex m-auto justify-center w-full'>
                      <a href="/test" className="text-center hover:scale-105 rounded-full bg-[#7035de] hover:bg-opacity-80 text-white py-2 px-6 text-sm font-bold md:py-3 md:px-12 md:text-lg">Take Test</a>
                    </div>
                  </div>
                </Transition.Child>
              </Transition.Child>
            </Dialog>
          </Transition.Root>

        </div>
      </div>
    </div>
  );
};

export default Header;
