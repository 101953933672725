import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export function useModal() {
  return useContext(ModalContext);
}

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareId, setShareId] = useState("");

  function showModal(share_id = "") {
    setShareId(share_id)
    setIsModalOpen(true);
  }

  function hideModal() {
    setIsModalOpen(false);
  }

  return (
    <ModalContext.Provider value={{ isModalOpen, showModal, hideModal,shareId }}>
      {children}
    </ModalContext.Provider>
  );
};
