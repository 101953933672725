
import AuthModal from '../../components/AuthModal';
import Header from '../../components/Header';
import Results from './Results.js';
import { useLocation } from 'react-router-dom';


export default function ResultsShare() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shareID = queryParams.get('id');
    return (
        <div className='bg-[#080421] min-h-full'>
            <div className='min-h-screen'>
                <Header></Header>
                <AuthModal></AuthModal>
                <Results withBack={false} fromPublic={true} resultsShareID={shareID}></Results>
            </div>
        </div>
    )
}
