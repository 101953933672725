import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [error, setError] = useState(null);

    const checkTokenValidity = (token) => {
        return new Promise((resolve, reject) => {
            axios.get("https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF" + '/auth/me')
                .then(response => {
                    console.log(response.status);
                    if (response.status !== 200) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                })
                .catch(error => {
                    setError(error.response ? error.response.data.message : 'An unknown error occurred');
                    console.error('Token validation failed:', error);
                    resolve(false);
                });
        });
    };

    useEffect(() => {
        if (authToken) {
            localStorage.setItem('authToken', authToken);
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
            if (checkTokenValidity(authToken)) {
                console.log("In the Context USe effect")

                setIsUserLoggedIn(true);
            }
            else {
                logout()
            }
        } else {
            localStorage.removeItem('authToken');
            delete axios.defaults.headers.common['Authorization'];
            console.log('failed')
            setIsUserLoggedIn(false);
            logout()
        }

        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [authToken, user]);
    console.log(isUserLoggedIn, 'incontenxt')
    
    const login = async (data) => {
        try {
            const response = await axios.post("https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF" + '/auth/login', data);
            if (response.data.authToken) {
                await setAuthToken(response.data.authToken);
                console.log("Checking Token!!!")
                console.log(response.data.authToken)
                await setUser(response.data.user);
                await setIsUserLoggedIn(true)
                return response.data;
            }
        } catch (error) {
            setError(error.response ? error.response?.data?.message : 'An unknown error occurred');
            console.error('Login failed:', error);
        }
    };

    const signup = async (data) => {
        try {
            const response = await axios.post("https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF" + '/auth/signup', data);
            if (response.data.authToken) {
                await setAuthToken(response.data.authToken);
                console.log("Checking Token!!!")
                console.log(response.data.authToken)
                await setUser(response.data.user);
                await setIsUserLoggedIn(true)
                return response.data;
            }
        } catch (error) {
            setError(error.response ? error.response?.data?.message : 'An unknown error occurred');
            console.error('Login failed:', error);
        }
    };

    const logout = () => {
        console.log("Loggin Out...")
        setAuthToken(null);
        setUser(null);
        delete axios.defaults.headers.common['Authorization'];
        console.log('logged out')
        setIsUserLoggedIn(false)
    };

    const authContextValue = { user, authToken, isUserLoggedIn, login, signup, logout, error, setError };


    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
