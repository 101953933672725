import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://example.com/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Submission successful', result);
        // Handle success scenario (e.g., displaying a success message)
      } else {
        console.error('Submission failed', response.status);
        // Handle failure scenario
      }
    } catch (error) {
      console.error('Error submitting form', error);
      // Handle error scenario
    }
  };

  return (
    <form onSubmit={handleSubmit} className=" text-white px-4 rounded-lg">
      <p className="my-2 text-xs">Drop your name and email here to contact us</p>

      <div className=" rounded-lg border border-white px-4 py-2 my-2">
        <div className="flex justify-between items-center">
          <div className="w-full">
            <div className="text-white opacity-70 text-2xs pl-">Name </div>
            <input
              type="text"
              placeholder="John Doe"
              className="p-0 w-full bg-transparent border-none focus:ring-0 text-white"
            />
          </div>
        </div>
      </div>

      <div className=" rounded-lg border border-white px-4 py-2 my-2">
        <div className="flex justify-between items-center">
          <div className="w-full">
            <div className="text-white opacity-70 text-2xs pl-">Your Email</div>
            <input
              type="text"
              placeholder="Johndoe@gmail.com"
              className="p-0 w-full bg-transparent border-none focus:ring-0 text-white"
            />
          </div>
        </div>
      </div>
      <button type="submit" className="bg-[#fff] hover:bg-blue-700 text-black w-full my-5 font-bold py-2 px-4 rounded cursor-pointer">
        Submit
      </button>
    </form>
  );
}



const Footer = () => {
  return (
    <footer className="bg-dark-blue text-white p-10 mt-10 space-y-10">
      <div className="max-w-8xl mx-auto flex-col md:flex-row justify-between grid gap-10 grid-cols-5">
        <div className="col-span-5 md:col-span-2">
          <div className="flex flex-col ">

            <img
              srcSet="/tslogov2.png"
              alt="TypeScience Logo" className="h-24 w-24 self-center md:self-center" />
          </div>
          <div className="z-50 mx-auto items-center text-center mt-12 ">
            <p className="z-50 text-center items-center justify-center text-pretty md:text-center text-2xl md:text-[1.5rem] ">Unveil the layers of your personality and step into a journey of self-discovery with the TypeScience Test.</p>
          </div>
        </div>



        <div className="col-span-5 md:col-span-3 z-50">
          <div className='grid grid-cols-3'>
            <div className="col-span-2 md:col-span-1 md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-10">
              <div className="flex flex-col space-y-2">
                <span className="font-bold text-xs mb-4 uppercase">Information</span>
                {/* <Link to="/about-us" className=" font-family-[Poppins] text-sm font-f">About us</Link> */}
                <Link to="/methodology" className=" font-family-[Poppins] text-sm">Methodology</Link>
                <Link to="/framework" className=" font-family-[Poppins] text-sm">Framework</Link>
                <Link to="/personality-types" className=" font-family-[Poppins] text-sm">Personality Types</Link>
                <Link to="/blog" className=" font-family-[Poppins] text-sm">Blog</Link>
              </div>
            </div>
            <div className="col-span-1 md:col-span-1">
              <div className="col-span-1 flex flex-col space-y-2">
                <span className="font-bold text-xs mb-4 uppercase">Menu</span>
                <Link to="/terms-and-conditions" className=" font-family-[Poppins] text-sm">Terms and condition</Link>
                <Link to="/privacy-policy" className=" font-family-[Poppins] text-sm ">Privacy Policy</Link>
              </div>
            </div>
            <div className="col-span-3 md:col-span-1 mt-12 md:mt-0">
              <ContactForm></ContactForm>
            </div>
          </div>



        </div>

      </div>
      <div className="text-center !mt-5">
        <p>© 2023 TypeScience. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
