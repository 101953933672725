/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, SaveAsIcon } from '@heroicons/react/outline'
import ShareResultComponent from './ShareResult';
import axios from 'axios';

export default function ShareTestModal({ open, setOpen, test, updateTest, withSend,withPublicLink }) {
    const [inputValue, setInputValue] = useState('');
    const [resultDetail, setResultDetail] = useState(null);
    const [resultReady, setResultReady] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    function extractFunction(text) {
        const parts = text.split('-');
        if (parts.length > 1) {
            const functionPart = parts[1].split('/')[0];
            const secondFunctionPart = parts[1].split('/')[1].split('-')[0];
            return `${functionPart}/${secondFunctionPart}`;
        }
        // Return the original text if it doesn't match the expected format
        return text;
    }
    const fetchPersonalityTypeDetails = async () => {
        const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
        const cmsIdentifier = test.is_paid ? extractFunction(test.result) : test.result
        try {
            const response = await axios.get(`https://cms.typescience.ai/api/personality-types?filters[unique_name][$eq]=${cmsIdentifier}&populate=*`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setResultDetail(response)
            setResultReady(true)

        } catch (error) {
            console.error('Error fetching personality types:', error);
        }
    };

    function setOpenPrivate(){
        setOpen(false)
    }

    useEffect(() => {
        fetchPersonalityTypeDetails()
        setInputValue(test.name);
    }, [test]);
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={()=>setOpenPrivate()}>
                <div className="bg-opacity-0 bg-black flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="bg-[#080421] relative inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6 text-white">
                            {resultReady? <ShareResultComponent personlaity_name={resultDetail?.data?.data[0]?.attributes?.name} personality_mbti={resultDetail?.data?.data[0]?.attributes?.description} withSend={withSend} withPublicLink={withPublicLink} test={test}></ShareResultComponent>:<></>}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
