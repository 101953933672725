import React from 'react';

const Profile = () => {
    // Data for traits could also come from props or state
    const traits = {
        Introverted: 90,
        Intuitive: 80,
        Thinking: 80,
        Decider: 50,
        Prospecting: 85,
        Turbulent: 70,
        Attitude: 70,
    };

    const TraitBar = ({ name, percentage }) => (
        <div className="grid grid-cols-1 gap-1 items-center justify-between">
            <span className="text-gray-600 text-sm ">{name}</span>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div className="bg-[#773FCF] text-[10px] font-medium text-blue-100 text-center p-0.5 h-3 leading-none rounded-full" style={{ width: `${percentage}%` }}>{percentage}%</div>
            </div>
        </div>
    );

    return (
        <div className="md:pl-32 flex flex-col flex-1 ">
            <div className="grid grid-cols-1 md:grid-cols-12  text-white" >
                <div className='col-span-8'>
                    <div className="max-w-7xl sm:px-6 md:px-8 mx-auto md:mt-12">

                        <div className="bg-transparent shadow-lg rounded-lg p-6 md:my-8 text-white">
                            <div className='border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-6 my-8'>
                                <h2 className="text-xl font-grifter mb-4">Your Profile</h2>
                                <div className='grid grid-cols-1  md:grid-cols-12'>
                                    <div className='col-span-4 items-center justify-center text-center'>
                                        <img src="/avatar.png" alt="User avatar" className="mx-auto justify-center w-auto h-auto rounded-full mb-2" />
                                        <div className="flex justify-center ">
                                            <button className="bg-[#7940CF] px-10 py-2 mb-10 rounded-md hover:underline center">Unlock full result</button>
                                        </div>
                                    </div>
                                    <div className='col-span-8'>
                                        <div className="flex flex-row justify-between items-center mb-4">
                                            <div>
                                                <h3 className="text-lg">Fe/Se</h3>
                                                <p className="text-sm ">@salem</p>
                                            </div>
                                            <button className=" text-[#FCAA13] border border-[#FCAA13] px-4 py-2 rounded-lg">Share</button>
                                        </div>
                                        <p className='text-xs my-5'>Explore your personality test results to understand more about your personality<br></br> type and core traits.</p>
                                        <h6 className='my-4'>Your Traits</h6>
                                        <div className="mb-4 grid grid-cols-2 gap-4">
                                            {Object.entries(traits).map(([name, percentage]) => (
                                                <TraitBar className="col-span-1" key={name} name={name} percentage={percentage} />
                                            ))}
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div className="mt-6 border  border-[#4A1365] bg-[#493A65] bg-opacity-20 rounded-lg text-start p-12 my-8">
                                <div className='grid grid-cols-1 md:grid-cols-12'>
                                    <div className='col-span-10'>
                                        <p className="text-sm text-[#7940CF]">Upgrade Now</p>
                                        <p className="text-xl font-grifter">Unlock additional features with the Premium Suite.</p>
                                        <p>Unlock The Remaining 95% Of IT (Logician) insights Instantly And Turn Your Best Ideas Into Reality.</p>
                                    </div>
                                    <div className='col-span-2'>
                                        <button className="border border-[#7940CF] text-[#7940CF]  px-6 py-2 rounded-full mt-4">Upgrade</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-span-4 md:mt-20'>
                    <div className="md:fixed px-16 text-white">
                        <div className=' text-center'>
                            <div className="p-8 rounded-lg shadow-md space-y-5">
                                <h2 className="text-white text-xl mb-2">--Energy--</h2>
                                <div className="text-white text-4xl font-grifter mb-4">90% Introverted</div>
                                <p className="text-gray-500 text-sm mb-6 text-start">
                                    Lorem ipsum dolor sit amet consectetur adipiscing elit tempor, rutrum ultrices himenaeos dui sociis porta torquent praesent,
                                    interdum suspendisse pellentesque hendrerit facilisis feugiat vitae. Lectus risus interdum egestas erat arcu platea luctus senectus,
                                    blandit nascetur ullamcorper eros sagittis nostra augue, molestie ac proin etiam quis torquent at. Fusce gravida curabitur diam sed elementum,
                                    sollicitudin mus interdum inceptos arcu, potenti dignissim donec massa.
                                </p>
                                <div className="flex justify-center">
                                    <div className="w-16 h-16 bg-gray-200 flex items-center justify-center rounded-full">
                                        {/* Your icon here */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-purple-800 bg-opacity-10 border border-[#7940CF] mt-4 md:mb-0 mb-24 rounded-2xl p-10 shadow-lg">
                            <div className="text-lg font-bold">Promo Code</div>
                            <p className="my-2 text-sm text-gray-500">
                                Want to buy a test for later, or send one as a gift to friends and family? Promo codes are single-use codes that never expire and can be redeemed to unlock a premium result. You can purchase a single code, or get one FREE when you buy two!
                            </p>
                            <div className="flex justify-between gap-2">
                                <button className="border border-[#7940CF] text-[#7940CF] rounded py-2 px-4">
                                    Buy One
                                </button>
                                <button className="border border-[#7940CF] text-[#7940CF]  rounded py-2 px-4">
                                    Buy 3-pack
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
