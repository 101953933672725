import * as React from "react";
import Header from "../../components/Header";
import * as Style from '../Home/styles'
import AuthModal from "../../components/AuthModal";
import Footer from "../../components/Footer";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import TakeTestButton from "../../components/TakeTestButton";



function ContentComponent() {
  return (
    <main className="text-xl font-bold bg-[#080421] text-white justify-center items-center  ">
      <h4 className="text-center font-grifter text-4xl md:text-45xl mt-16 md:mt-16">Methodology</h4>
      <img
        className="z-0 absolute animate-float fade-image -translate-y-80"
        srcSet="bright.svg"
      ></img>
    </main>
  );
}
const Methodology = () => {
  return (

    <Style.Div>
      <AuthModal />
      <Header active="methodology"></Header>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeScience - Methodology</title>
        <meta name="description" content="Methodology" />
        <meta property="og:type" content="article" />
        <body className="root" />

      </Helmet>
      <ContentComponent></ContentComponent>
      <div className="z-10 w-full md:w-5/6 grid grid-cols-1 md:grid-cols-12 mx-auto p-10">

        <div className="col-span-12 md:text-mid lg:text-lg text-white bg-opacity-50 bg-[#5f5373] rounded-lg p-10 text-center lg:mx-60">
          TypeScience is built on the <a href="https://objectivepersonalitysystem.com/" target="_blank" className="text-blue-500">Objective Personality System (OPS)</a>, which is rooted in Carl Jung’s Cognitive Functions theory. Jung suggested that just as people have a dominant hand, they have preferred ways of processing information. These cognitive functions are the foundation of many major personality frameworks like Myers-Briggs (MBTI) and 16 Personalities.
        </div>

      </div>

      <div className="self-center flex w-full md:w-5/6 flex-col  md:max-w-full p-10">
        <div className="grid grid-cols-1 md:grid-cols-12 md:gap-8 my-10">
          <div className=" col-span-6 relative text-white my-auto">
            <div className="justify-center mx-auto bg-[#3F3351] p-8 bg-opacity-40 md:rounded-lg my-4 ">
              <h3 className="text-4xl md:text-4xl xl:text-6xl  font-grifter text-center mb-4 ">Science-Driven</h3>
              <p className="mb-4 md:text-mid lg:text-lg text-center">
                OPS is unique among personality frameworks in that it was developed with the <i>scientific method</i>. Each personality component is based not on theory, but on what is objectively observable by independent researchers. It is so refined that two researchers can guess the same personality components with over 99% accuracy! 
              </p>
            </div>

            <img
              className="absolute fade-image -translate-x-96 md:translate-x-0"
              srcSet="bright.svg"
            ></img>
          </div>
          <div className="col-span-1 md:col-span-6 m-auto items-center justify-center text-center align-middle">
            <img
              className="rounded-lg"
              src="methodoloyx1.webp"
            ></img>
          </div>



        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 md:gap-8">
          <div className="col-span-1 md:col-span-6 mx-auto">
            <img
              className=" mx-auto"
              srcSet="objective_scoring.png"
            ></img>
          </div>
          <div className="col-span-6 text-white p-8 bg-[#3F3351] md:rounded-lg my-auto bg-opacity-40">
            <div className="justify-center mx-auto	">
              <h3 className="text-4xl md:text-4xl xl:text-6xl mb-4 font-grifter text-center">Objective Assessment</h3>
              <p className="mb-4 md:text-mid lg:text-lg text-center">
                TypeScience uses an objective, data-driven approach to scoring. Your answers are compared against a large, scientifically analyzed dataset to ensure accuracy. To further reduce bias, TypeScience encourages feedback from people who know you well, providing a well-rounded and objective assessment.              </p>
            </div>
          </div>
        </div>

        <div className="my-24">
          <TakeTestButton></TakeTestButton>
          <p className="text-center text-mid text-white my-5"> Try it free, no email required.</p>
        </div>

      </div>
      <Footer></Footer>
    </Style.Div>
  );
}

export default Methodology;