import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TakeTestButton from './TakeTestButton';

// The container will be flex-direction column on mobile and row on larger screens
const Container = styled.div`
  display: flex;
  flex-wrap: wrap; // Allows items to wrap as needed
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) { // Adjust the breakpoint as needed
    flex-direction: row;
  }
  margin-top:1%;  
  background-image: url(group-12.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 768px) { 
    background-image: none;
  }

  `;


const Child = styled.div`
    display: flex; // Enables flexbox
    flex-direction: column; // Stacks cards vertically
    align-items: center; // Centers cards horizontally in the flex container
    justify-content: center; // Centers cards vertically in the flex container
    flex-basis: 100%; // Takes full width on mobile
    flex-grow: 1; // Grow to fill the space if there's any
    box-sizing: border-box; // Include padding and border in the element's total width and height
    color: white;
    // Add some padding inside the Child component

    @media (min-width: 768px) { // Adjust the breakpoint as needed
    flex-basis: 50%; // Takes half width on larger screens
    }
`;

const Card = styled.div`
    background-color: rgba(255, 255, 255, 0.14);
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 15px;
    margin:50px;
    align-items:center;
    width: auto; // Adjust width as needed
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px); // Apply blur effect
    transition: box-shadow 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(20px); // For Safari

    border-radius: 44px;
    // min-height: 379px;
    max-width: 445px;

  &:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(25px); // For Safari
  }

  // Fallback for browsers that do not support backdrop-filter
  @supports not ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
    background-color: rgba(44, 44, 44, 0.95); // Less transparent for better readability
  }
}
`;

// Styled title for each card
const Title = styled.h2`
  margin-bottom: 10px;
  font-family: 'GrifterBold', sans-serif;
`;

// Styled text for each card
const Text = styled.p`
  font-size: 1.1em;
  
`;

const MyComponent = () => {
  return (
    <Container className='w-full md:w-4/5 relative m-auto'>
      <img
        className="xl:hidden animate-pulse absolute z-0 !w-full fade-image overflow-hidden"
        srcSet="bright.svg"
      ></img>
      <img
        className="xl:hidden animate-pulse absolute z-0 -translate-y-64 !w-full fade-image overflow-hidden"
        srcSet="bright.svg"
      ></img>
      <Child>
        <Card>
          <Title className='text-11xl leading-tight' >A Powerful RoadMap for Personal Growth</Title>
          <Text>
          Discovering who you are today is the first step to becoming who you will be tomorrow." Take charge of your journey with a personal Roadmap that cuts through the guesswork. Learn from the paths others like you have taken to achieve success, and navigate life's challenges with confidence.
          </Text>
        </Card>
        <img
          className="xl:hidden -translate-y-96 absolute z-0 !w-full fade-image overflow-hidden"
          srcSet="bright.svg"
        ></img>
         <Card className='!w-3/4'>
          <Title className='text-11xl leading-tight'>Thrive With Your Tribe</Title>
          <Text className=''>Miscommunications and misunderstandings often lead to conflicts. 
            Understanding your own and others' operational modes enhances connections like never before. 
            Use this knowledge to foresee synergies, navigate conflicts, and explore potential relationships, 
            transforming how you interact with the world around you.
          </Text>
        </Card>
      </Child>
      <img
        className="xl:hidden animate-pulse absolute z-0 translate-y-64 !w-full fade-image"
        srcSet="bright.svg"
      ></img>
      <Child >
        <Card >
          <Title className='text-11xl leading-tight'>Optimize Your Career</Title>
          <Text>TypeScience reveals your natural tendencies and strengths, 
            guiding you to careers that best suit your unique traits. 
            Discover the role you were born to play in a professional setting, 
            where your work feels like a natural extension of who you are.
          </Text>
        </Card>
       
      </Child>
      <div className='text-white mt-16'>
        <TakeTestButton></TakeTestButton>
        <p className="text-center text-mid my-5"> Try it free, no email required.</p>

      </div>
    </Container>
  );
};

export default MyComponent;