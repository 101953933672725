/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios';

import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
  CogIcon,
  LogoutIcon
} from '@heroicons/react/outline'
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


const navigation = [
  { name: 'My Tests', href: '/dashboard', icon: HomeIcon, currentName: "dashboard" },
  // { name: 'Personality Profile', href: '/dashboard/personality-profile', icon: UsersIcon, currentName: "personality-profile" },
  // { name: 'Stats', href: '/dashboard/stats', icon: FolderIcon, currentName: "stats" },
  // { name: 'Terms', href: '/dashboard/terms', icon: CalendarIcon, currentName: "terms" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentComponent, setCurrrentComponent] = useState("dashboard")

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-[#272932]">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex justify-center items-center px-4">
                  <a href='/'>
                    <img
                      className="h-12  w-auto"
                      alt="TS"
                      src="/tslogov2.png"
                    />
                  </a>
                  <p className='font-grifter text-white my-auto'>Home</p>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      onClick={() => setCurrrentComponent(item.currentName)}
                      className={classNames(
                        item.currentName === currentComponent ? 'bg-[#080421] text-white' : 'text-[#8F8F8F] hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.currentName === currentComponent ? 'text-gray-300' : 'text-[#8F8F8F] group-hover:text-gray-300',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
              <hr className=' mx-4 '></hr>
              <div className="">
                {/* <a
                  href={"/a"}
                  className=' hover:bg-gray-700 hover:text-white flex text-center justify-center items-center px-2 pr-0 py-4  mr-0 text-sm font-medium rounded-tl-full rounded-bl-full '
                >
                  <CogIcon
                    className='text-[#8F8F8F] group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                    aria-hidden="true"
                  />
                </a> */}
                <a
                  href={"/"}
                  className='hover:bg-gray-700 hover:text-white flex text-center justify-center items-center px-2 pr-0 py-4  mr-0 text-sm font-medium rounded-tl-full rounded-bl-full '
                >
                  <LogoutIcon
                    className='text-[#8F8F8F] group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-24 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-[#272932] rounded-tr-xl rounded-br-xl shadow-custom-gray">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center text-center justify-center flex-shrink-0 px-4">
              <a href='/'>
                <img
                  className="h-12  w-auto"
                  alt="TS"
                  src="/tslogov2.png"
                />
                <p className='font-grifter my-4 text-white'>Home</p>
              </a>
            </div>

            <nav className="mt-5 flex-1 px-2 pr-0 space-y-2">
              {navigation.map((item) => (
                <>
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => setCurrrentComponent(item.currentName)}
                    className={classNames(
                      item.currentName === currentComponent ? 'bg-[#080421] text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      ' flex text-center justify-center items-center px-2 pr-0 py-4 mr-0 text-sm font-medium rounded-tl-full rounded-bl-full '
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.currentName === currentComponent ? 'text-[#7038CF]' : 'text-[#8F8F8F] group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                  </Link>
                </>
              ))}
            </nav>
          </div>
          <hr className=' mx-4 '></hr>
          <div className="">
            {/* <a
              href={"/a"}
              className=' hover:bg-gray-700 hover:text-white flex text-center justify-center items-center px-2 pr-0 py-4  mr-0 text-sm font-medium rounded-tl-full rounded-bl-full '
            >
              <CogIcon
                className='text-[#8F8F8F] group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                aria-hidden="true"
              />
            </a> */}
            <a
              href={"/"}
              className='hover:bg-gray-700 hover:text-white flex text-center justify-center items-center px-2 pr-0 py-4  mr-0 text-sm font-medium rounded-tl-full rounded-bl-full '
            >
              <LogoutIcon
                className='text-[#8F8F8F] group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6'
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
        <button
          type="button"
          className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

    </div>
  )
}