import React from 'react';
import styled from 'styled-components';
import * as Style from '../pages/Home/styles';
import Accordion from './Accordion'
const FAQ = () => (
  <Style.Div95 className='relative'>
    <div className="w-full m-auto justify-center">
      <img
        className="-translate-y-64 absolute -translate-x-48 w-[1400px] h-[1400px] z-0 t-0 fade-image"
        srcSet="bright.svg"
      ></img>
    </div>
    <div className='relative grid grid-cols-12'>
      <div className='col-span-12 md:col-span-6'>
        <Style.Div97>
          <Style.Div98 className='text-center md:text-start'>Frequently Asked Questions</Style.Div98>

          <img

            className="w-full object-auto object-center self-center "
            loading="lazy"
            src="owl_curiousx.png"
            // srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a4fdb73b5638d0169f9c74c588eab5b4850c2398163b83f7db34ccea00896f34?apiKey=2d1d137607f749e6b268ecb1ba4f2080&"
          />
        </Style.Div97>
      </div>
      <div className="col-span-12 md:col-span-6 mt-0 z-50" style={{ lineHeight: 'normal' }}>
        <Accordion></Accordion>
        <br></br>
      </div>

    </div>
  </Style.Div95>
);

export default FAQ;
