

const Loading = () => {
    return (
        <div className="flex w-10">
            <img className="w-5 h-5 animate-spin" src="https://www.svgrepo.com/show/199956/loading-loader.svg" alt="Loading icon"/>
        </div>
    );
}

export default Loading;
