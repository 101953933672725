import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Image = ({ src, alt, role = 'presentation', className }) => {
  return <img src={src} alt={alt} role={role} className={className} loading="lazy" />;
};

const SectionTitle = ({ children, className }) => {
  return <h2 className={`mt-4 text-3xl md:text-6xl max-md:text-4xl ${className}`}>{children}</h2>;
};

const SectionContent = ({ children, className }) => {
  return <div className={` mt-12 text-xs md:text-sm max-md:mt-10 max-md:max-w-full ${className}`}>{children}</div>;
};

const BeginButton = ({ children, className }) => {
  return (
    <a href="/survey" className={`justify-center px-9 py-3 mt-16 text-xl leading-9 text-black whitespace-nowrap bg-white border border-black border-solid shadow-sm rounded-xl max-md:px-5 max-md:mt-10 ${className}`} tabIndex="0">
      {children}
    </a>
  );
};

function JourneyStartPage() {
  const [Instructions, setInstructionsText] = useState([]);

  useEffect(() => {
    const fetchPersonalityTypes = async () => {
      const token = "0585347c92a761c69f60773b0f289cb06dd4991780b517d0507ba7aa0bf8376ed6f48430e4ba332e655dc74b160ffc9ca92ea050b4c8e6c2e4c7bba93feea72fbb3060d118edab771c12f9886eb8837093bdc73d6a145495dc387a2fe026b1ad99ae6ae35ccba3709a7838bba459431f3464176a9e363e34c43d97adddb0f9d0";
      try {
        const response = await axios.get('http://localhost:1331/api/question-instruction',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data)
        setPersonalityTypes(response.data.data);
      } catch (error) {
        console.error('Error fetching personality types:', error);
      }
    };

    fetchPersonalityTypes();
  }, []);

  return (
    <main className="flex flex-col justify-center text-xl font-bold text-center text-white bg-slate-950 2">
      <img
        className="hidden xl:flex absolute w-screen h-screen z-0 t-0 r-0 fade-image"
        srcSet="instruction_bright.svg"
      ></img>

      <section className="flex overflow-hidden relative flex-col justify-center w-full max-md:max-w-full">
        <Image src="https://cdn.builder.io/api/v1/image/assets/TEMP/86aaa6fa894d88543bb014614537e2f1c6681043376d82dafff740dfdc294380?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="" className="animate-pulse opacity-10 object-cover absolute inset-0 size-full" />
        <div className="flex overflow-hidden relative flex-col items-center px-16 pt-4 pb-2.5 mt-3.5 w-full min-h-[788px] mix-blend-plus-lighter max-md:px-5 max-md:max-w-full">
          <Image src="https://cdn.builder.io/api/v1/image/assets/TEMP/90ba412b9ad7ce2916a0f5a867f304b3724b0db3879256a9b6b3c83beb9e0d82?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="" className="animate-float opacity-50 object-cover absolute inset-0 size-full" />
          <div className="flex relative gap-5 justify-between items-center w-full max-w-[1161px] max-md:flex-wrap max-md:max-w-full">
            {/* <Image src="https://cdn.builder.io/api/v1/image/assets/TEMP/64bafbadaecdbe4120bf123f4f7abdca3411ada065a95b785bb2a7a4e2228000?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="Icon 1" className="self-stretch hidden md:flex my-auto max-w-full aspect-square w-[118px]" /> */}
            <div className="flex flex-col flex-1 items-center self-stretch max-md:max-w-full">
              <a href="/"><Image src="tslogov2.png" alt="Icon 2" className="aspect-[1]" /></a>
              <h2 className='text-3xl md:text-4xl'>Introduction</h2>
              <div className="font-poppins w-3/4 sm:w-1/2 mt-6 mb-8 text-sm md:text-lg">
                Discover your personality type in just 15-20 minutes with our scientific assessment based on the Objective Personality system. Discover which of the 32 archetypes you are! It’s free—no email required.
              </div>
              <div className='bg-[#3F3351] rounded-md sm:rounded-lg  sm:w-3/4 p-6 sm:p-8 mt-2'>
              <SectionTitle>Instructions</SectionTitle>
              <div className="font-poppins text-sm md:text-lg mt-6">
                If someone is watching you take the test, or you are hungry, sad, tired, or otherwise not in a normal mood, it is better to stop now and save this test for later.
                Usually your first impulse is correct, or else it’s better to consider your natural inclinations early in life more than how you are now.
                Answer each question according to your natural self and what was most true for most of your early life. Try to ignore your upbringing, trauma, and cultural influences.
                Also think how parents, friends, and ex's would answer for you. Be completely honest. There are no good/bad or right/wrong answers.
              </div>
              </div>
              <BeginButton className="transition ease-in-out delay-150 hover:scale-105 mb-24 ">Begin Your Journey</BeginButton>
            </div>
            {/* <Image src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ee1e10a56a020d2b0eeb7bb0c6c9bd5da2e960c51557a8094eb3b39a63b6d7a?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="Icon 3" className="self-stretch hidden md:flex my-auto max-w-full aspect-square w-[118px]" /> */}
          </div>
        </div>
      </section>
    </main>
  );
}

export default JourneyStartPage;