import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import './Blogs.css';
import { Link } from 'react-router-dom';

const BlogDetail = () => {
  const { id } = useParams(); 
  const [post, setPost] = useState(null);

  const fetchPersonalityTypeDetails = async () => {
    const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
    try {
      const response = await axios.get(`https://cms.typescience.ai/api/blog-posts?filters[id][$eq]=${id}&populate=*`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data)
      setPost(response.data.data[0]);

    } catch (error) {
      console.error('Error fetching personality types:', error);
    }
  };

  useEffect(() => {
    fetchPersonalityTypeDetails()
  }, []);

  if (!post) {
    return <div>Loading...</div>;
  }

  const sanitizedContent = DOMPurify.sanitize(post?.attributes?.content);
  
  const date = new Date(post?.attributes?.createdAt);
  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
});
  return (
    <div className="blog-detail md:w-3/4 mx-auto text-white">
       <main className="text-xl font-bold bg-[#080421] text-white justify-center items-center  ">
            <h4 className="text-center font-grifter text-4xl md:text-45xl mt-16 md:mt-16"></h4>
            <img
                className="z-0 absolute animate-float fade-image -translate-y-80"
                srcSet="bright.svg"
            ></img>
        </main>
      <h1 className='text-white text-4xl font-grifter my-4'>{post?.attributes?.title}</h1>
      <p className='my-8 text-sm text-gray-500'>By : Austin Ollar - {formattedDate}</p>
      <div className='blog_detail font-grifter' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      {/* <div>{ReactHtmlParser(sanitizedContent)}</div> */}
      <Link to={"/test"}>
      <img src={"/blog-bottom-banner.png"} alt={"take-the-test"} className='w-full h-auto rounded-xl my-10 transition hover:scale-105 hover:cursor-pointer' />
      </Link>
    </div>
  );
};

export default BlogDetail;
