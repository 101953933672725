const TimelineSection = () => {
  const timelineData = [
    {
      id: '',
      text: 'Whereas other frameworks are based purely on theory, OPS is grounded in what is objectively observable by many different people.'
    },
    {
      id: '',
      text: 'Whereas other Jungian frameworks reduce you to one of only 16-32 types, OPS has discovered thousands.'
    },

  ];
  const timelineData2 = [
    {
      id: 'C',
      text: 'Whereas other tests are assessed solely based on the designers’ opinions, TypeScience was developed using data from hundreds of the individuals who were independently analyzed in double-blind tests by OPS researchers.'
    },
    {
      id: 'D',
      text: 'Whereas other tests are prone to self-bias, TypeScience enhances objectivity by allowing you to invite people who know you well to take the test on your behalf'
    }
  ]

  return (
    <div className="bg-dark-blue text-white my-10  px-10 flex-col z-50">
      <p className="text-white text-center mt-10 text-[48px] leading-normal font-bold font-grifter" >
        Discover Real Insights with <strong className="bg-[#7940CF] p-2 rounded-lg"> <span class="text-[#FFF] mx-1 font-extrabold text-[48px] relative inline-block stroke-current">
          TypeScience
          <svg class="text-[#7940CF] absolute w-full max-h-1.5" viewBox="0 0 55 5" xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none">
            <path d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002" stroke-width="2"></path>
          </svg>
        </span></strong>

      </p>
      <div className="text-white text-center text-sm xl:text-xl my-9 md:whitespace-normal">
        TypeScience is powered by the <a href="https://objectivepersonalitysystem.com/" target="_blank" className="text-blue-500">Objective Personality System (OPS)</a>
      </div>
      <img
        className="xl:hidden  -translate-x-48  absolute z-0 !w-full fade-image overflow-hidden"
        srcSet="bright.svg"
      ></img>

      <img
        className="xl:hidden translate-y-48  -translate-x-48  absolute z-0 !w-full fade-image"
        srcSet="bright.svg"
      ></img>
      <div className="grid grid-cols-1 md:grid-cols-2 mx-auto gap-12 w-4/5 relative">
        <img
          className="absolute -translate-y-64 z-0 !w-full fade-image-radial"
          srcSet="bright.svg"
        ></img>
        <div className="col-span-1  bg-opacity-50 bg-[#3F3351]  rounded-lg p-6">
          <p className="text-[#FFF] text-lg">TypeScience is grounded in what is consistently observable in reality, not just theory.</p>

        </div>
        <div className="col-span-1 bg-[#7940CF]  bg-opacity-50 rounded-lg p-6">
          <p className="text-[#FFF] text-lg">Enhance objectivity by inviting people who know you well to take the test on your behalf.
          </p>
        </div>
        <div className="col-span-1 bg-[#3F3351]  md:bg-[#7940CF] bg-opacity-50 rounded-lg p-6">
          <p className="text-[#FFF] text-lg">Receive precise, personalized results by testing against hundreds of proven Types.</p>

        </div>
        <div className="col-span-1 bg-opacity-50 bg-[#7940CF] sm:bg-[#3F3351] rounded-lg p-6">
          <p className="text-[#FFF] text-lg">Our test is refined by hundreds of double-blind assessments conducted by experienced OPS researchers.</p>
        </div>
      </div>



    </div>
  );
};

export default TimelineSection;
