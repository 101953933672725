import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import axios from 'axios';

const settings = {
  dots: true,
  infinite: true,
  speed: 5000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  infinite: true,
};

// Container for the entire client testimonial section
const CarouselContainer = styled.section`
  margin:10px;
  align-self: center;
`;

// Individual testimonial card
const TestimonialCard = styled.div`
    position: relative;
    margin: 5px;
    margin-top:100px;
    padding:75px 10px 75px;
    display: inline-block;
    max-width: 25em;
    background-color: rgba(255, 255, 255, 0.14);
    border-radius: 10px;
    backdrop-filter: blur(45px);
    -webkit-backdrop-filter: blur(20px); // For Safari

    &:after {
        content: "";
        position: absolute;
        inset: 0;
        z-index:-1;
        border-radius: 10px;
        padding: 2px; /* control the border thickness */
        background: linear-gradient(45deg, #ccc, #080421);
        -webkit-mask: 
          linear-gradient(#fff 0 0) content-box, 
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
        -webkit-backdrop-filter: blur(20px); // For Safari

    }
`;

// Client's name and title
const ClientName = styled.h3`
  margin: 20px;
  z-index:10;
  color: #FFFFFF;
`;

const ClientTitle = styled.p`
color: #FFFFFF;
margin: 20px;

  z-index:10;

`;
const ClientImage = styled.img`
    margin: 20px;
    color: #FFFFFF;
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
    margin-top: -150px;
    z-index:999;

`

// Client's statement
const ClientStatement = styled.p`
  font-style: italic;
  z-index:10;
  margin: 20px;
  color: #FFFFFF;


  // Add more styles as necessary
`;

// Navigation arrows (not functional in this static example)
const NavArrow = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  // Add more styles as necessary
`;

// Replace with actual client data
const clientsx = [
  {
    name: 'Jaydon Bator',
    title: 'Sr. Tester',
    statement: '512 Types has been a game-changer for me! The insights into my personality have been eye-opening. It\'s like having a roadmap to navigate my own life.',
    imageUrl: "https://xsgames.co/randomusers/assets/avatars/male/49.jpg"
  },
  {
    name: 'Jaydon Bator',
    title: 'Sr. Tester',
    statement: '512 Types has been a game-changer for me! The insights into my personality have been eye-opening. It\'s like having a roadmap to navigate my own life.',
    imageUrl: "https://xsgames.co/randomusers/assets/avatars/male/49.jpg"
  },
  {
    name: 'Jaydon Bator',
    title: 'Sr. Tester',
    statement: '512 Types has been a game-changer for me! The insights into my personality have been eye-opening. It\'s like having a roadmap to navigate my own life.',
    imageUrl: "https://xsgames.co/randomusers/assets/avatars/male/49.jpg"
  },
  {
    name: 'Jaydon Bator',
    title: 'Sr. Tester',
    statement: '512 Types has been a game-changer for me! The insights into my personality have been eye-opening. It\'s like having a roadmap to navigate my own life.',
    imageUrl: "https://xsgames.co/randomusers/assets/avatars/male/49.jpg"
  },
];

const ClientTestimonials = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
      try {
        const response = await axios.get('https://cms.typescience.ai/api/testimonials', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        )
        console.log(response.data.data)
        setClients(response.data.data);
      } catch (error) {
        console.error("Failed to fetch clients", error);
      }
    };

    fetchClients();
  }, []);
  return (
    <>
      {
        clients.length!==0 ?
          <CarouselContainer className=' max-w-screen-xl'>
            <Slider {...settings}>
              {clients.map((client, index) => (
                <TestimonialCard key={index}>
                  <ClientImage src={client.attributes.imageUrl} alt={`${client.attributes.name}`} />
                  <ClientStatement>{client.attributes.statement}</ClientStatement>
                  <ClientName>{client.attributes.name}</ClientName>
                  <ClientTitle>{client.attributes.title}</ClientTitle>
                </TestimonialCard>
              ))}
            </Slider>
          </CarouselContainer>
          :
          <></>
      }
    </>

  );
}
export default ClientTestimonials;
