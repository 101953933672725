import styled from "styled-components";

export const Div = styled.div`
  background-color: #080421;
  display: flex;
  padding-bottom: 50px;
  flex-direction: column;
  overflow: hidden;
`;
export const Div2 = styled.div`
  disply: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  min-height: 1121px;
  width: 100%;
  align-items: center;
  color: #fff;
  font-weight: 700;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;
export const Img = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;
export const Div3 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1250px;
  justify-content: space-between;
  gap: 20px;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;
export const Img2 = styled.img`
  aspect-ratio: 1.14;
  object-fit: auto;
  object-position: center;
  width: 157px;
  max-width: 100%;
`;
export const Div4 = styled.div`
  display: flex;
  align-items: center;
  font-family:Poppins;
  justify-content: space-between;
  gap: 30px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;
export const Div5 = styled.div`
  font-family: Poppins;
  align-self: stretch;
  flex-grow: 1;
  margin: auto 0;
  font-size: 1.25em;
`;
export const Div6 = styled.div`
align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
`;
export const Div7 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;
export const Div8 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;
export const Div9 = styled.button`
  justify-content: center;
  border-radius: 108px;
  background-color: #fff;
  align-self: stretch;
  flex-grow: 1;
  color: #000;
  white-space: nowrap;
  text-align: center;
  padding: 11px 49px;
  font: 700 21px/165.3% DM Sans, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
export const Div10 = styled.div`
  justify-content: center;
  border-radius: 108px;
  background-color: #7035de;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  text-align: center;
  padding: 11px 49px;
  font: 700 21px/165.3% DM Sans, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
export const Div11 = styled.div`
  position: relative;
  text-align: center;
  margin-top: 92px;
  font: 64px/64px GRIFTER, sans-serif;
  font-family:'GrifterBold', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
    line-height: 44px;
  }
`;
export const Div12 = styled.div`
  position: relative;
  text-align: center;
  margin-top: 43px;
  width: 854px;
  font-family: Poppins;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div13 = styled.div`
  position: relative;
  justify-content: center;
  border-radius: 108px;
  border: 1px solid #000;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  gap: 5px;
  font-size: 21px;
  color: #000;
  white-space: nowrap;
  text-align: center;
  line-height: 165.3%;
  margin: 40px 0;
  padding: 20px 67px;
  @media (max-width: 991px) {
    margin-bottom: 40px;
    white-space: initial;
    padding: 0 20px;
  }
`;
export const Div14 = styled.div`
  font-family: DM Sans, sans-serif;
  flex-grow: 1;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
export const Img3 = styled.img`
  aspect-ratio: 1.59;
  object-fit: auto;
  object-position: center;
  width: 30px;
  stroke-width: 3px;
  stroke: #000;
  margin: auto 0;
`;
export const Img4 = styled.img`
  object-fit: auto;
  top:50%;
  object-position: center;
  width: 100vw;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Img41 = styled.img`
  object-fit: auto;
  top:80%;
  object-position: center;
  width: 100vw;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div15 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 838px;
  margin-top: 261px;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  color: #fff;
  padding: 0 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;
export const Div16 = styled.div`
  position: relative;
  align-self: end;
  display: flex;
  margin-top: -136px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div17 = styled.div`
  border-radius: 44px;
  backdrop-filter: blur(45px);
  background-color: rgba(255, 255, 255, 0.14);
  display: flex;
  flex-direction: column;
  padding: 48px 39px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;
export const Div18 = styled.div`
  font: 700 32px/32px GRIFTER, sans-serif;
`;
export const Div19 = styled.div`
  margin: 28px 0 22px;
  font: 400 16px/23px Beatrice Deck Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
`;
export const Div20 = styled.div`
  border-radius: 44px;
  backdrop-filter: blur(45px);
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  margin-top: 199px;
  flex-direction: column;
  padding: 50px 39px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;
export const Div21 = styled.div`
  margin-top: 19px;
  font: 700 32px/100.3% GRIFTER, sans-serif;
`;
export const Div22 = styled.div`
  margin: 40px 0 32px;
  font: 400 16px/23px Beatrice Deck Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
`;
export const Div23 = styled.div`
  position: relative;
  border-radius: 44px;
  backdrop-filter: blur(45px);
  background-color: rgba(255, 255, 255, 0.14);
  align-self: start;
  display: flex;
  margin-top: 51px;
  flex-direction: column;
  padding: 49px 41px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;
export const Div24 = styled.div`
  white-space: nowrap;
  font: 700 32px/100.3% GRIFTER, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
export const Div25 = styled.div`
  margin-top: 32px;
  font: 400 20px/29px Beatrice Deck Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
`;
export const Div26 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 129px;
  width: 100%;
  max-width: 1400px;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div27 = styled.div`
  color: #fff;
  text-align: center;
  font: 700 68px/64px GRIFTER, sans-serif;
  font-family:'GrifterBold', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    line-height: 44px;
  }
`;
export const Div28 = styled.div`
  position: relative;
  margin-top: 57px;
  width: 905px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
export const Div29 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 15%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 118px;
  max-width: 100%;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  position:absolute;
  top:0;
`;
export const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 68%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div30 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 701px;
  flex-grow: 1;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div31 = styled.div`
  disply: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: 701px;
  width: 100%;
  padding: 42px 60px 19px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;
export const Img9 = styled.img`
  position:absolute;
  top:0;
  aspect-ratio: 0.56;
  object-fit: auto;
  object-position: center;
  width: 100%;
  @media (max-width: 991px) {
    margin: 0 2px 0 3px;
  }
`;
export const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 16%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div32 = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #fff;
  white-space: nowrap;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;
export const Div33 = styled.div`
  font: 700 48px/100.3% GRIFTER, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;
export const Div34 = styled.div`
  text-overflow: ellipsis;
  margin-top: 65px;
  font: 400 14px/20px Beatrice Deck Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;
export const Img10 = styled.img`
  aspect-ratio: 1.06;
  object-fit: auto;
  object-position: center;
  width: 126px;
  align-self: center;
  margin-top: 34px;
`;
export const Div35 = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 108px;
  border: 1px solid #000;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-top: 24px;
  width: 251px;
  max-width: 100%;
  color: #000;
  white-space: nowrap;
  text-align: center;
  padding: 10px 60px;
  font: 700 21px/165.3% DM Sans, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
export const Div36 = styled.div`
  color: #fff;
  margin-top: 40px;
  font: 800 52px/100.3% GRIFTER, sans-serif;
  font-family:'GrifterBold', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;
export const Div37 = styled.div`
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 37px;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;
export const Img11 = styled.img`
  aspect-ratio: 0.89;
  object-fit: auto;
  object-position: center;
  width: 25px;
  fill: #15bffd;
  align-self: start;
  margin: 73px 0 0 38px;
  @media (max-width: 991px) {
    margin: 40px 0 0 10px;
  }
`;
export const Div38 = styled.div`
  display: flex;
  margin-top: 14px;
  width: 930px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;
export const Img12 = styled.img`
  aspect-ratio: 0.88;
  object-fit: auto;
  object-position: center;
  width: 100%;
  fill: #15bffd;
  margin-top: 9px;
  flex: 1;
`;
export const Img13 = styled.img`
  aspect-ratio: 0.89;
  object-fit: auto;
  object-position: center;
  width: 100%;
  fill: #15bffd;
  flex: 1;
`;
export const Div39 = styled.div`
  align-self: stretch;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div40 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 59%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div41 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div42 = styled.div`
  display: flex;
  padding-right: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  font-weight: 700;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;
export const Div43 = styled.div`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div44 = styled.div`
  color: #fff;
  font: 32px/94% GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div45 = styled.div`
  color: #b4c5cb;
  margin-top: 16px;
  font: 16px/24px GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Img14 = styled.img`
  aspect-ratio: 12.5;
  object-fit: auto;
  object-position: center;
  width: 64px;
  stroke-width: 1px;
  stroke: var(--Gredient, #15bffd);
  align-self: stretch;
  margin: auto 0;
`;
export const Div46 = styled.div`
  background-color: #15bffd;
  border-radius: 50%;
  align-self: stretch;
  width: 24px;
  height: 24px;
  margin: auto 0;
`;
export const Div47 = styled.div`
  align-self: end;
  display: flex;
  margin-top: 34px;
  gap: 0px;
  padding: 0 1px;
`;
export const Div48 = styled.div`
  background-color: #15bffd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;
export const Img15 = styled.img`
  aspect-ratio: 11.11;
  object-fit: auto;
  object-position: center;
  width: 64px;
  stroke-width: 1px;
  stroke: var(--Gredient, #15bffd);
  margin: auto 0;
`;
export const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 41%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div49 = styled.div`
  display: flex;
  margin-top: 124px;
  flex-grow: 1;
  padding-bottom: 12px;
  flex-direction: column;
  font-weight: 700;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div50 = styled.div`
  color: #fff;
  font: 32px/94% GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div51 = styled.div`
  color: #b4c5cb;
  margin-top: 16px;
  font: 16px/24px GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div52 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 107px;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  font-weight: 700;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;
export const Div53 = styled.div`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div54 = styled.div`
  color: #fff;
  font: 32px/94% GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div55 = styled.div`
  color: #b4c5cb;
  margin-top: 16px;
  font: 16px/24px GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Img16 = styled.img`
  aspect-ratio: 11.11;
  object-fit: auto;
  object-position: center;
  width: 64px;
  stroke-width: 1px;
  stroke: var(--Gredient, #15bffd);
  align-self: stretch;
  margin: auto 0;
`;
export const Div56 = styled.div`
  background-color: #15bffd;
  border-radius: 50%;
  align-self: stretch;
  width: 24px;
  height: 24px;
  margin: auto 0;
`;
export const Div57 = styled.div`
  align-self: end;
  display: flex;
  width: 595px;
  max-width: 100%;
  justify-content: space-between;
  gap: 0px;
  font-weight: 700;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;
export const Div58 = styled.div`
  background-color: #15bffd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: auto 0;
`;
export const Img17 = styled.img`
  aspect-ratio: 11.11;
  object-fit: auto;
  object-position: center;
  width: 64px;
  stroke-width: 1px;
  stroke: var(--Gredient, #15bffd);
  align-self: start;
  margin-top: 56px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
export const Div59 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div60 = styled.div`
  color: #fff;
  font: 32px/94% GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div61 = styled.div`
  color: #b4c5cb;
  margin-top: 16px;
  font: 16px/24px GRIFTER, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div62 = styled.div`
  align-self: stretch;
  margin: 18px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 10px 0 0;
  }
`;
export const Div63 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 62%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div64 = styled.div`
  color: #fff;
  font: 700 64px/64px GRIFTER, sans-serif;
  font-family: 'GrifterBold', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
    line-height: 45px;
  }
`;
export const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 38%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div65 = styled.div`
  width: 100%;
  padding:10px;
  font: 700 200px/100.3% GRIFTER, sans-serif;
  font-family: 'GrifterBold', sans-serif;
  background: linear-gradient(0deg, #00F0FF 2.92%, #FF1CF7 101.04%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 991px) {
    margin-top: 40px;
    font-size: 40px;
  }
`;
export const Div66 = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-weight: 700;
  margin: 41px 19px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin: 40px 10px 0 0;
  }
`;
export const Div67 = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 108px;
  border: 1px solid #000;
  box-shadow: 8px 8px 0px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  color: #000;
  white-space: nowrap;
  text-align: center;
  padding: 20px 60px;
  font: 21px/165.3% DM Sans, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
export const Div68 = styled.div`
  color: #fff;
  align-self: center;
  flex-grow: 1;
  flex-basis: auto;
  font: 18px/10% GRIFTER, sans-serif;
  font-family:'Poppins', sans-serif;
`;
export const Div69 = styled.div`
  color: #fff;
  text-align: center;
  font: 700 52px/130% Trap, sans-serif;
  font-family: 'GrifterBold', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
  }
`;
export const Div70 = styled.div`
  margin-top: 44px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div71 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 77%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div72 = styled.div`
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 39px;
  }
`;
export const Div73 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column9 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 84%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div74 = styled.div`
  z-index: 10;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div75 = styled.div`
  z-index: 10;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div76 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column10 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 37%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div77 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 33px;
  }
`;
export const Div78 = styled.div`
  background-color: linear-gradient(180deg, #73d4ff 0%, #0167b1 100%);
  border-radius: 50%;
  align-self: end;
  width: 194px;
  height: 194px;
`;
export const Img18 = styled.img`
  aspect-ratio: 0.76;
  object-fit: auto;
  object-position: center;
  width: 304px;
  z-index: 10;
  margin-top: -124px;
  max-width: 100%;
`;
export const Column11 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 63%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div79 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 398px;
  margin-top: 70px;
  flex-grow: 1;
  align-items: start;
  color: #fff;
  font-weight: 400;
  padding: 1px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Img20 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 159px;
  border-radius: 50%;
  z-index: 10;
  max-width: 100%;
  margin: -69px 0 0 22px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;
export const Div80 = styled.div`
  position: relative;
  align-self: stretch;
  margin-top: 38px;
  font: 16px Beatrice Display Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div81 = styled.div`
  disply: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  display: flex;
  aspect-ratio: 1.24;
  margin-top: 6px;
  text-align: justify;
  padding: 47px 61px 47px 28px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
export const Div82 = styled.div`
  position: relative;
  margin-top: 57px;
  font: 24px Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
export const Div83 = styled.div`
  position: relative;
  margin-top: 11px;
  font: 18px Poppins, sans-serif;
`;
export const Div84 = styled.div`
  background-color: linear-gradient(180deg, #5901b1 0%, #ff73b6 100%);
  border-radius: 50%;
  align-self: center;
  margin-top: -37px;
  width: 116px;
  height: 116px;
`;
export const Column12 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 16%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div85 = styled.div`
  background-color: linear-gradient(180deg, #73d4ff 0%, #0167b1 100%);
  border-radius: 50%;
  width: 194px;
  height: 194px;
  margin: 0 auto;
`;
export const Column13 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 23%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div86 = styled.div`
  display: flex;
  margin-top: 70px;
  flex-grow: 1;
  flex-direction: column;
  color: #fff;
  font-weight: 400;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
export const Div87 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 10;
  display: flex;
  aspect-ratio: 0.8;
  width: 100%;
  align-items: start;
  padding: 1px 20px;
`;
export const Img23 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 159px;
  border-radius: 50%;
  z-index: 10;
  max-width: 100%;
  margin: -69px 0 0 22px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;
export const Div88 = styled.div`
  position: relative;
  align-self: stretch;
  z-index: 10;
  margin-top: 36px;
  font: 16px Beatrice Display Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
`;
export const Div89 = styled.div`
  disply: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  display: flex;
  aspect-ratio: 1.24;
  margin-top: -16px;
  text-align: justify;
  padding: 45px 61px 45px 28px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
export const Div90 = styled.div`
  position: relative;
  margin-top: 57px;
  font: 24px Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
export const Div91 = styled.div`
  position: relative;
  margin-top: 10px;
  font: 18px Poppins, sans-serif;
`;
export const Div92 = styled.div`
  background-color: linear-gradient(180deg, #5901b1 0%, #ff73b6 100%);
  border-radius: 50%;
  margin-top: -37px;
  height: 116px;
`;
export const Div93 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 19px;
  width: 100%;
  max-width: 1315px;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  top:80%
`;
export const Div94 = styled.div`
  mix-blend-mode: plus-lighter;
  z-index: auto;
  background-image: url("https://cdn.builder.io/api/v1/image/assets/TEMP/afaf2696095ee86841aa70eefaede43a777e2ddf698b0e52d2f251f6443444c3?apiKey=2d1d137607f749e6b268ecb1ba4f2080&");
`;
export const Img25 = styled.img`
  aspect-ratio: 0.97;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex: 1;
`;
export const Img26 = styled.img`
  aspect-ratio: 0.97;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex: 1;
`;
export const Div95 = styled.div`
  margin-top: 80px;
  width: 100%;
  max-width: 1154px;
  align-self:center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div96 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 43%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div97 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
    line-height: 53px;
  }
`;
export const Div98 = styled.div`
  font-family: "GrifterBold", sans-serif;
  background: linear-gradient(75deg, #fff 2.92%, #d7b8ff 101.04%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    line-height: 53px;
  }
`;
export const Img27 = styled.img`
  aspect-ratio: 0.65;
  object-fit: auto;
  object-position: center;
  width: 378px;
  align-self: center;
  margin-top: 27px;
  max-width: 100%;
`;
export const Column15 = styled.div`
  margin-top:10em;
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 57%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div99 = styled.div`
  display: flex;
  margin-top: 200px;
  flex-direction: column;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
export const Div100 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 0 1px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;
export const Div101 = styled.div`
  font-family: "GrifterBold", sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;
export const Img28 = styled.img`
  aspect-ratio: 0.79;
  object-fit: auto;
  object-position: center;
  width: 11px;
  align-self: start;
`;
export const Div102 = styled.div`
  margin-top: 25px;
  font: 400 15px/21px Beatrice Deck Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  color:#aaa;
  font-family:Poppins;
`;
export const Div103 = styled.div`
  font-family: "GrifterBold", sans-serif;
  margin-top: 25px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Img29 = styled.img`
  object-fit: auto;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #fff;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div104 = styled.div`
font-family: "GrifterBold", sans-serif;
margin-top: 33px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Img30 = styled.img`
  object-fit: auto;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #fff;
  margin-top: 28px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div105 = styled.div`
  font-family: Beatrice Trial, sans-serif;
  margin-top: 33px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Img31 = styled.img`
  object-fit: auto;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #fff;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div106 = styled.div`
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  margin-top: 29px;
  font: 11px/140% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div107 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 5em;
  width: 100%;
  align-items: center;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;
export const Img32 = styled.img`
  aspect-ratio: 1.04;
  object-fit: auto;
  object-position: center;
  width: 165px;
  max-width: 100%;
`;
export const Div108 = styled.div`
  margin-top: 9px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div109 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column16 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 72%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div110 = styled.div`
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 16px;
  }
`;
export const Div111 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
export const Column17 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div112 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 140%;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;
export const Div113 = styled.div`
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font: 500 10px Inter, sans-serif;
`;
export const Div114 = styled.div`
  font-family: Beatrice Trial, sans-serif;
  margin-top: 24px;
`;
export const Div115 = styled.div`
  font-family: Beatrice Trial, sans-serif;
  margin-top: 4px;
`;
export const Div116 = styled.div`
  font-family: Beatrice Trial, sans-serif;
  margin-top: 4px;
`;
export const Div117 = styled.div`
  font-family: Beatrice Trial, sans-serif;
  margin-top: 4px;
`;
export const Div118 = styled.div`
  font-family: Beatrice Trial, sans-serif;
  margin-top: 4px;
`;
export const Column18 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div119 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 140%;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;
export const Div120 = styled.div`
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font: 500 10px Inter, sans-serif;
`;
export const Div121 = styled.div`
  font-family: Inter, sans-serif;
  margin-top: 24px;
`;
export const Div122 = styled.div`
  font-family: Inter, sans-serif;
  margin-top: 4px;
`;
export const Column19 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 28%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const Div123 = styled.div`
  align-items: end;
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  line-height: 130%;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;
export const Div124 = styled.div`
  justify-content: center;
  border-radius: 8px;
  box-shadow: 10px 14px 0px 0px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  align-self: stretch;
  color: #000;
  padding: 13px 34px;
  font: 14px/140% Inter, sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
export const Div125 = styled.div`
  font-family: Inter, sans-serif;
  margin-top: 16px;
`;
export const Div126 = styled.div`
  font-family: Inter, sans-serif;
`;
export const Div127 = styled.div`
  display: flex;
  gap: 16px;
  color: #fff;
  margin: 94px 0 0 -137px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;
export const Div128 = styled.div`
  letter-spacing: -0.8px;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 40px/40px Beatrice Deck Trial, -apple-system, Roboto, Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div129 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  line-height: 140%;
  padding: 2px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div130 = styled.div`
  font: 500 14px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
export const Div131 = styled.div`
  justify-content: space-between;
  align-items: start;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.45);
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  padding: 8px 80px 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;
export const Div132 = styled.div`
  letter-spacing: 0.4px;
  text-transform: uppercase;
  white-space: nowrap;
  font: 500 10px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
export const Div133 = styled.div`
  margin-top: 4px;
  font: 400 16px Inter, sans-serif;
`;
export const Div134 = styled.div`
  color: #fff;
  margin-top: 89px;
  white-space: nowrap;
  font: 700 16px GRIFTER, sans-serif;
  font-family: "Poppins", sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;