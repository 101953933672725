import * as React from "react";
import * as Style from '../Home/styles'
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import AuthModal from "../../components/AuthModal";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";


import { Link } from 'react-router-dom';
import TakeTestButton from "../../components/TakeTestButton";

const Home = () => {

    return (
        <Style.Div>
            <AuthModal />
            <Header active="framework"></Header>
            <Helmet>
                <meta charSet="utf-8" />
                <title>TypeScience - Framework</title>
                <meta name="description" content="Frameworks" />
                <meta property="og:type" content="article" />
                <body className="root" />

            </Helmet>
            <main className="text-xl font-bold bg-[#080421] text-white justify-center items-center  ">
                <h4 className="text-center font-grifter text-4xl md:text-45xl mt-16 md:mt-16">Framework</h4>
                <img
                    className="z-0 absolute animate-float fade-image -translate-y-80"
                    srcSet="bright.svg"
                ></img>
            </main>
            <section className="z-10 mt-10 text-white w-5/6 md:w-4/5 mx-auto">

                <div className=""><p className="text-xl mb-10 text-center">While the OPS framework is quite complicated, TypeScience breaks down the most useful components in an easy to digest fashion.</p></div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="col-span-1  text-mid text-white bg-[#3F3351] bg-opacity-30 rounded-lg p-10 my-5">
                        <h2 className=" text-4xl mt-5 font-grifter ">Personality Type</h2>
                        Your cognitive functions unconsciously prioritize mental tools to preserve energy and optimize outcomes, determining your personality type based on their sequential use. In the theory of Jungian typology this will often be a code like INFJ.
                    </div>
                    <div className="col-span-1 align-middle text-center items-center self-center py-auto ">
                        <img className="my-auto mx-auto w-64 h-64" src="32x.png"></img>
                    </div>
                </div>

                <h2 className="text-4xl my-10 font-grifter"> Quadrants</h2>
                <div className="col-span-12 text-sm text-white  bg-[#3F3351] rounded-sm p-5 md:p-10 my-5">
                    <p className="text-mid leading-snug md:text-xl ">Classifications of fundamental human needs that shape your personality dynamics. Often, individuals strive to fulfill contradictory goals by overemphasizing their dominant traits. For instance, Organizers may believe that by being excessively responsible in the present, they will attain greater freedom in the future, yet in reality, this often leads to the opposite outcome. This innate drive compels us to become proficient specialists within society.</p>
                    <br></br>
                    <div className="grid md:grid-cols-12 grid-cols-1">
                        <img className="md:hidden col-span-1" src="me.png"></img>
                        <ul className="md:col-span-6 col-span-1 gap-10 my-auto  ">
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter text-2xl">Mind</strong> - A version involving cognitive processes related to logic, analysis, rationality, and objective thinking with a focus on logical reasoning, problem-solving, and critical analysis when making decisions. This can involve a preference for thinking about facts, figures, and objective information. Called Thinking in Jungian Typology.</li>
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter text-2xl">Heart</strong> - A version involving emotional processes, values, subjective feelings, and empathy. This can involve a preference for empathizing with others, considering personal values, and making decisions based on emotional resonance. Called Feeling in Jungian Typology.</li>
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter  text-2xl">Concrete</strong> - A version pertaining to tangible, observable, and factual information that is grounded in reality. Concrete information is often related to sensory experiences and practical details. Called Sensing in Jungian Typology.</li>
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter  text-2xl">Abstract</strong> - A version involving conceptual, theoretical, and non-tangible ideas that are not necessarily tied to specific sensory experiences. Abstract information is more focused on concepts, theories, and principles rather than immediate sensory input. Called iNtuition in Jungian Typology.</li>
                        </ul>
                        <img className="hidden w-3/4 mx-auto md:flex md:col-span-6 text-center col-span-1" src="me.png"></img>
                    </div>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <img className="md:col-span-6 w-3/4 mx-auto col-span-1" src="Quadrant1.png"></img>
                        <ul className="md:col-span-6 col-span-1 my-auto ">
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter text-2xl">Individualists</strong> - Tend to prioritize their own inner thoughts and feelings (Heart or Mind, depending on version) over those of the group, emphasizing personal perspectives.</li>
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter text-2xl">Collaborators</strong> - Primarily consider a broader range of collective thoughts and feelings (Heart or Mind, depending on version) over their individual ones, valuing group perspectives.</li>
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter text-2xl">Explorers</strong> - Prefer acquiring new information, whether Concrete or Abstract (depending on version), before consulting existing knowledge, prioritizing novelty and exploration.</li>
                            <li className="my-2 py-1 text-lg"><strong className="font-grifter text-2xl"> Organizers</strong> - Refer to existing Concrete or Abstract information (depending on version) before seeking new alternatives, prioritizing structure and orderliness in decision-making.</li>
                        </ul>
                    </div>

                </div>
                <h2 className="text-4xl my-10 font-grifter">Subtypes (Coming Soon) </h2>
                <div className="col-span-12 text-sm text-white bg-opacity-30  bg-[#3F3351] rounded-lg p-5 md:p-10 my-5">
                    <p className="text-mid leading-snug md:text-xl ">Explore your social inclinations concerning ego and interpersonal connections, as well as your inclination towards specialization or generalization. These factors collectively shape your perception of social hierarchy, regardless of its actuality. This feature will unlock for you retroactively if upgrading to Premium test results.</p>
                    <br></br>
                    <ul className="gap-10">
                        <li className="my-4 md:my-0 block md:flex gap-2">
                            <img className="w-24 h-24 mx-auto" src="Alpha-Icon.png"></img>
                            <p className="text-lg my-auto"><stronger className="text-2xl font-grifter">Alphas </stronger> -  Primarily driven by a desire to excel and achieve superiority, followed by a tendency towards generalization. Interestingly, these characteristics closely align with the traits associated with the Slytherin house.</p>
                        </li>
                        <li className="my-4 md:my-0 block md:flex gap-2">
                            <img className="w-24 h-24 mx-auto" src="Conductor-Icon4.png"></img>
                            <p className="text-lg my-auto"><stronger className="text-2xl font-grifter">Conductors </stronger> -   Mainly driven by a desire to manage multiple responsibilities and tasks as a generalist, with a secondary emphasis on social connections. Remarkably, these qualities closely correspond to the attributes of the Gryffindor house..</p>
                        </li>
                        <li className="my-4 md:my-0 block md:flex">
                            <img className="w-24 h-24 mx-auto" src="Expert-Icon.png"></img>
                            <p className="text-lg my-auto"><stronger className="text-2xl font-grifter">Experts </stronger> -   Mainly driven by a desire to specialize and excel in a particular area of expertise, prioritizing mastery over being the best overall. Interestingly, these characteristics closely align with the traits associated with the Ravenclaw house.</p>
                        </li>
                        <li className="my-4 md:my-0 block md:flex">
                            <img className="w-24 h-24 mx-auto" src="Teammate-Icon.png"></img>
                            <p className="text-lg my-auto"><stronger className="text-2xl font-grifter">Teammates </stronger> -  Primarily driven by a need for social connection and camaraderie, followed by a desire to contribute a specific skill or expertise. Surprisingly, these qualities closely correspond to the attributes of the Hufflepuff house.</p>
                        </li>
                    </ul>
                </div>
                <h2 className="text-4xl my-10 font-grifter">Prescriptions</h2>
                <div className="text-sm text-white bg-[#3F3351] rounded-sm p-10 my-5">
                    <p className="col-span-12 text-xl my-4 "> Prescriptions - Areas of life where you can enhance your well-being through specific actions and behaviors, determined by Cognitive Functions working together. These prescriptions correspond to seasonal themes, reflecting behaviors commonly observed during different times of the year.</p>
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-x-12">
                        <div className="col-span-6 my-5  bg-opacity-75 bg-[#5f5373] rounded-lg p-2 md:p-5">
                            <img className="w-48 mx-auto mb-4" src="https://cms.typescience.ai/uploads/spring_icon_6e08bd54cf.png"></img>
                            <p className="text-4xl font-grifter text-center mb-4">Spring </p>
                            <p className="text-mid md:text-2xl my-auto px-1 leading-normal md:px-6 pb-4 text-center md:leading-relaxed">   A season for exploration and learning, where the world undergoes renewal. To benefit, engage in activities such as reading, researching, and observing to acquire new knowledge and update your perspectives in alignment with the unfolding cycle.</p>
                        </div>
                        <div className="col-span-6 my-5  bg-opacity-75 bg-[#5f5373] rounded-lg p-2 md:p-5">
                            <img className="w-48 mx-auto mb-4" src="https://cms.typescience.ai/uploads/summer_icon_7b9bcb1c9d.png"></img>
                            <p className="text-4xl font-grifter text-center mb-4">Summer </p>
                            <p className="text-mid md:text-2xl my-auto px-6 pb-4 text-center leading-relaxed">A time of vibrant social interaction and practical application of insights amid favorable weather conditions. Embrace opportunities for engagement and play with others and the environment, such as conducting interviews and collaborating on projects, to deepen understanding and integrate learning.</p>
                        </div>
                        <div className="col-span-6 my-5  bg-opacity-75 bg-[#5f5373] rounded-lg p-2 md:p-5">
                            <img className="w-48 mx-auto mb-4" src="https://cms.typescience.ai/uploads/fall_icon_c4196a511f.png"></img>
                            <p className="text-4xl font-grifter text-center mb-4">Fall </p>
                            <p className="text-mid md:text-2xl my-auto px-6 pb-4 text-center leading-relaxed"> A period of productivity and implementation, characterized by reaping the rewards of earlier efforts before the onset of winter. Maximize benefits by applying existing knowledge and skills in productive endeavors, taking action to initiate projects and diligently work towards achieving goals.</p>
                        </div>
                        <div className="col-span-6 my-5  bg-opacity-75 bg-[#5f5373] rounded-lg  p-2 md:p-5">
                            <img className="w-48 mx-auto mb-4" src="https://cms.typescience.ai/uploads/winter_icon_0992931482.png"></img>
                            <p className="text-4xl font-grifter text-center mb-4">Winter </p>
                            <p className="text-mid md:text-2xl my-auto px-6 pb-4 text-center leading-relaxed">A season for introspection and conservation of energy, as external conditions become less conducive to activity. To thrive, prioritize activities such as resting, reflecting, and engaging in introspective practices, allowing for the preservation of mental and emotional resources and the resolution of complex challenges.</p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 h-fit gap-6">
                    <div className=" col-span-1  bg-[#3F3351]  rounded-lg">
                        <img className="mx-auto my-auto p-10" src="coinsx.png"></img>

                    </div>
                    <div className="h-full col-span-1 text-xl text-white bg-opacity-50 bg-[#3F3351] rounded-lg p-10">
                        <h2 className="text-center text-4xl my-4 font-grifter">Coins</h2>

                        In accordance with the Objective Personality System, your personality is delineated by your inclinations along numerous A or B trait axes. Unlocking Premium Test Results will provide greater depth by revealing your scores on each of these coins, accompanied by confidence ratings for each assessment. This comprehensive analysis aids in identifying areas of strong alignment as well as potential misinterpretations, empowering you to refine your understanding of your personality type.
                    </div>
                </div>
            </section>
            <diV className="my-16">
                <TakeTestButton></TakeTestButton>
                <p className="text-center text-mid text-white my-5"> Try it free, no email required.</p>

            </diV>
            <Footer></Footer>
        </Style.Div>
    );
}

export default Home;