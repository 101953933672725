import * as React from "react";
import * as Style from './styles'
import Description from "../../components/Description"
import ClientTestimonials from "../../components/Testimonials";
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import AuthModal from "../../components/AuthModal";
import Header from "../../components/Header";
import PersonalityShowcase from "../../components/PersonalityShowcase";
import TimelineSection from "../../components/TimelineSection";
import TimelineSection2 from "../../components/TimelineSection2";
import HomeHero from "../../components/Hero";
import AccuracySection from "../../components/Accuracy";
import Breakthrough from '../../components/Breakthrough'
import { Helmet } from "react-helmet";

const Home = () => {

  return (
    <Style.Div>
      <AuthModal />
      <Header></Header>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TypeScience - Methodology that Transcends Theory</title>
        <meta name="description" content="Methodology that Transcends Theory" />
        <meta property="og:type" content="article" />
        <body className="root" />

      </Helmet>
      <HomeHero></HomeHero>
      <Breakthrough></Breakthrough>
      <Description></Description>

      <div className="self-center flex w-5/6 flex-col items-center  md:mt-10 md:max-w-full">
        {/* <PersonalityShowcase></PersonalityShowcase> */}
        <TimelineSection2></TimelineSection2>
        {/* <TimelineSection></TimelineSection> */}
        <AccuracySection></AccuracySection>
        <FAQ></FAQ>
        <Footer></Footer>

      </div>

    </Style.Div>
  );
}

export default Home;