import React, { useState } from 'react';
import Questionairex from './Questionairex'

const questions = [
  {
    "id": 1,
    "attributes": {
      "question_name": "Seconds",
      "question_text": "Pull out your phone, turn on the stopwatch, then immediately (without seeing how long a second is) close your eyes and try to guess when it has been 30 seconds. How far off were you? This question is required.*",
      "question_type": "choice",
      "choices": {
        "A": "0-2s",
        "B": "3-5s",
        "C": "6-8s",
        "D": "9+s",
        "E": "Not Doing This!"
      },
      "createdAt": "2024-03-05T13:33:15.466Z",
      "updatedAt": "2024-03-05T13:33:17.582Z",
      "publishedAt": "2024-03-05T13:33:17.578Z",
      "negative_word": null,
      "positive_word": null,
      "orientation": "vertical",
      "img_url": null,
      "index": 1
    }
  },
  {
    "id": 2,
    "attributes": {
      "question_name": "SleepTime",
      "question_text": "I naturally like to sleep this many hours per day.",
      "question_type": "choice",
      "choices": {
        "A": "5 or fewer",
        "B": "6",
        "C": "7",
        "D": "8",
        "E": "9",
        "F": "10 or more"
      },
      "createdAt": "2024-03-06T07:41:06.638Z",
      "updatedAt": "2024-03-06T07:41:07.856Z",
      "publishedAt": "2024-03-06T07:41:07.850Z",
      "negative_word": null,
      "positive_word": null,
      "orientation": "vertical",
      "img_url": null,
      "index": 2
    }
  }
];


const Questionnaire = () => {
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const [answers, setAnswers] = useState({});

  // const handleAnswer = (choiceKey) => {
  //   const currentQuestion = questions[currentIndex].attributes;

  //   setAnswers({
  //     ...answers,
  //     [currentQuestion.question_name]: currentQuestion.choices[choiceKey],
  //   });

  //   // Move to the next question or finish if it was the last question
  //   if (currentIndex < questions.length - 1) {
  //     setCurrentIndex(currentIndex + 1);
  //   } else {
  //     console.log('Finished! Answers:', answers);
  //     // Here you could trigger any final action, like sending the answers somewhere
  //   }
  // };

  // if (questions.length === 0) {
  //   return <p>Loading questions...</p>;
  // }

  // const currentQuestion = questions[currentIndex].attributes;

  return (
    <Questionairex className=""></Questionairex>
    // <div className="max-w-xl mx-auto my-8 p-5 border rounded shadow">
    //   <h2 className="text-lg font-bold">{currentQuestion.question_text}</h2>
    //   {currentQuestion.img_url && (
    //     <img
    //       src={currentQuestion.img_url}
    //       alt={currentQuestion.question_name}
    //       className="my-4"
    //     />
    //   )}
    //   <div>
    //     {Object.entries(currentQuestion.choices).map(([key, value]) => (
    //       <button
    //         key={key}
    //         className="block w-full text-left py-2 px-4 my-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    //         onClick={() => handleAnswer(key)}
    //       >
    //         {value}
    //       </button>
    //     ))}
    //   </div>
    // </div>
  );
};

export default Questionnaire;
