import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate
} from "react-router-dom";
import { AnimatePresence } from 'framer-motion';

import Home from "./pages/Home/Home.js";
import { ModalProvider } from './contexts/ModalContext.js';
import { AuthProvider } from './contexts/AuthContext.js';
import useAuth from './hooks/useAuth.js';
import Methodology from "./pages/Methodology/Methodology.js";
import Framework from "./pages/Framework/Framework.js";
import Framework2 from "./pages/Framework/Framework2.js";
import PersonalityTypes from "./pages/PersonalityTypes/PersonalityTypes.js";
import PersonalityTypesDetail from "./pages/PersonalityTypes/PersonalityTypesDetail.js";
import Mission from "./pages/Mission/Mission.js";
import About from "./pages/About/About.js";
import DashboardPage from "./pages/Dashboard/Dashboard.js";
import Test from "./pages/Test/Test.js";
import Questionnaire from "./pages/Test/Questionnaire.js";
import Blog from "./pages/Blogs/Blogs.js";
import ResultsShare from "./pages/Dashboard/ResultsShare.js";
import Terms from './pages/Terms/Terms.js'
import Privacy from './pages/Privacy/Privacy.js'
import NotFound from './pages/404/404.js'

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/" />;
};

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      // Add more cases as needed
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <AuthProvider>
      <ModalProvider>
        <AnimatePresence mode='wait'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/methodology" element={<Methodology />} />
            <Route path="/framework" element={<Framework />} />
            <Route path="/frameworkOld" element={<Framework2 />} />
            <Route path="/personality-types" element={<PersonalityTypes />} />
            <Route path="/personality-types/*" element={<PersonalityTypesDetail />} />
            <Route path="/personality-types/*" element={<PersonalityTypesDetail />} />
            {/* <Route path="/mission" element={<Mission />} /> */}
            {/* <Route path="/about-us" element={<About />} /> */}
            <Route path="/test" element={<Test />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/share" element={<ResultsShare />} />
            <Route path="/survey" element={<Questionnaire />} />
            <Route path="/blog/*" element={<Blog />} />
            <Route path="/dashboard/*" element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            } />
            <Route path="*" element={<NotFound />} />

          </Routes>
        </AnimatePresence>
      </ModalProvider>
    </AuthProvider>
  );
}

export default App;
