import { Link } from 'react-router-dom';
import TakeTestButton from './TakeTestButton';

const Breakthrough = () => {
    return (
        <div className="text-white  mx-auto">
            <p className="text-white text-center text-[36px] md:text-[52px] font-bold font-grifter mb-10" >
                Breakthrough Personality Testing
            </p>
           <TakeTestButton></TakeTestButton>
            <p className="text-center text-mid my-5"> Free. No email required.</p>
        </div>
    )
}

export default Breakthrough;
