import { Link } from "react-router-dom";

const AccuracySection = () => {
    return (
        <div className="relative z-50 ">
            <img
                className="animate-pulse md:-translate-y-64 fade-image-radial absolute m-auto opacity-100 z-0"
                srcSet="81-bg.svg"
            ></img>
            <div className="relative my-4 md:my-10 md:mr-2.5 md:ml-0 ">
                <div className="grid grid-cols-12 w-4/5 mx-auto ">

                    <div className="col-span-12 md:col-span-6 my-10 text-center">
                        <div className="text-white text-center md:px-8 text-13xl md:text-13xl font-md:max-w-1/2 md:leading-[45px] font-grifter">
                        Interested to understand the science behind our test?
                        </div>
                        <Link to={"/methodology"} className="mx-auto w-3/4 md:w-1/2 justify-center transition ease-in-out delay-150 hover:scale-105 hover:bg-opacity-80 flex rounded-full border  border-black bg-white shadow-[0_8px_0px_rgba(0,0,0,0.25)] mt-10 py-5  gap-2 text-black text-lg font-bold md:mb-10 md:px-5">
                            <p className="text-xs md:text-lg">Learn More</p>
                        </Link>
                       
                    </div>
                    <div className="col-span-12 md:col-span-6 justify-center text-center items-center align-middle mx-auto">
                        <div className="w-full  text-transparent bg-clip-text font-bold text-[120px] md:mt-10 md:text-[150px]"
                            style={{
                                fontFamily: "'GrifterBold', sans-serif",
                                background: 'linear-gradient(0deg, #00F0FF 2.92%, #FF1CF7 101.04%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}>
                            81%
                        </div>
                        <p className="text-white self-center flex-grow font-[18px] leading-none" style={{ fontFamily: "'Poppins', sans-serif" }}>
                            Accuracy at predicting traits, and improving.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AccuracySection;
