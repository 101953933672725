import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import BlogList from './BlogList';
import BlogDetail from './BlogDetail';
import AuthModal from "../../components/AuthModal";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import * as Style from '../Home/styles'



function ContentComponent() {
    return (
        <main className="text-xl font-bold bg-[#080421] text-white justify-center items-center  ">
            <h4 className="text-center font-grifter text-4xl md:text-45xl mt-16 md:mt-16">Blog</h4>
            <img
                className="z-0 absolute animate-float fade-image -translate-y-80"
                srcSet="bright.svg"
            ></img>
        </main>
    );
}
function BlogLX() {
    return (
        <>
            <Style.Div>
                <AuthModal />
                <Header active="blog"></Header>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>TypeScience - Methodology that Transcends Theory</title>
                    <meta name="description" content="Methodology that Transcends Theory" />
                    <meta property="og:type" content="article" />
                    <body className="root" />

                </Helmet>
                <ContentComponent></ContentComponent>
                <div className='z-50 mb-24'>
                    <BlogList></BlogList>
                </div>
                <Footer></Footer>
            </Style.Div>
        </>
    )
}
function BlogDX() {
    return (
        <>
            <Style.Div>
                <AuthModal />
                <Header active="blog"></Header>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>TypeScience - Methodology that Transcends Theory</title>
                    <meta name="description" content="Methodology that Transcends Theory" />
                    <meta property="og:type" content="article" />
                    <body className="root" />

                </Helmet>
                <div className='z-50 mb-24 w-3/4 mx-auto'>
                    <BlogDetail></BlogDetail>
                </div>
                <Footer></Footer>
            </Style.Div>
        </>
    )
}
const Blogs = () => {
    return (

        <Routes>
            <Route path="/" element={<BlogLX />} />
            <Route path="/detail/:id" element={<BlogDX/>} />
        </Routes>

    );
};



export default Blogs;