
import { Route, Routes } from 'react-router-dom';
import DashboardNavigator from "./DashboardNavigator"
import DashboardHome from './DashboardHome';
import PersonalityProfile from './PersonalityProfile'
import Results from './Results'
import { Helmet } from "react-helmet";
import Footer from 'src/components/Footer';


export default function Dashboard() {
  return (
    <div className='bg-[#080421] !min-h-screen '>
      <div className="!min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TypeScience - Dashboard</title>
          <meta name="description" content="Methodology that Transcends Theory" />
          <meta property="og:type" content="article" />
          <body className="root" />

        </Helmet>
        <DashboardNavigator></DashboardNavigator>
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/personality-profile" element={<PersonalityProfile />} />
          <Route path="/results" element={<Results />} />
        </Routes>


      </div>
    </div>
  )
}
