import { Link } from 'react-router-dom';

const TakeTestButton = () => {
    return (
        <div className="px-8 py-0 font-grifter !z-50">
            <div className="grid gap-8 items-start justify-center">
                <div className="relative group  hover:scale-105 transition ease-in-out delay-150  hover:bg-opacity-80 ">
                    <div className="absolute  bg-gradient-to-r from-[#fff] to-[#fff] rounded-full  opacity-75 blur-sm group-hover:opacity-100 transition duration-1000 group-hover:duration-200 "></div>
                    <Link to="/test" className="shadow-[0_8px_0px_rgba(f,f,f,0.25)] py-5 px-16 gap-2 flex rounded-full justify-center transition ease-in-out delay-150  relative border-white leading-none  items-center divide-x divide-gray-600 bg-[#7035de] hover:bg-opacity-80 text-white">
                        <span className="flex items-center space-x-0">

                            <p className="text-[14px] md:text-[20px] pr-6 text-center justify-center cente text-white">Take the Test</p>
                            <p className='text-2xl text-white'>{">"}</p>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default TakeTestButton;
