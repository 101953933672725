import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';

const BlogPost = () => {
  const [posts, setPosts] = useState([]);

  const fetchPersonalityTypeDetails = async () => {
    const token = "6b1875cbfb2e50bbe3805dbb0385a8b14e707bdbf2325674ddaad125239e7d3401f8aa2cca4094a3ffa38f6f790d510ada111a5f03626ebe6661ddbf95456582d5fa9795ee4c2958fb72a8cf3187897b219cd2551eddf83e2232de063f0a95c4ebad38bd5245e3a18e986a939db189a728793103eba8c2fe75128c921d7085d3";
    try {
      const response = await axios.get(`https://cms.typescience.ai/api/blog-posts?populate=*`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data)
      setPosts(response.data.data);

    } catch (error) {
      console.error('Error fetching personality types:', error);
    }
  };

  useEffect(() => {
    fetchPersonalityTypeDetails()
  }, []);


  return (
    <div className='w-4/5 mx-auto my-24'>

      <ul className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'>
        {posts?.map(post => (
          <Link to={`/blog/detail/${post?.id}`}>
            <div key={post?.attributes?.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-105 transition ease-in-out delay-300 hover:cursor-pointer py-12">
              <img className="w-full h-48 object-cover" src={"http://localhost:1331"+post?.attributes?.image?.data?.attributes?.url} alt={post.title} />
              <div className="p-4 text-black">
                <h2 className="text-xl font-bold mb-2"><Link to={`/blog/${post.id}`}>{post?.attributes?.title}</Link></h2>
                <p className="text-gray-700">{post?.attributes?.excerpt}</p>
                <Link to={`/blog/detail/${post?.id}`} className="text-blue-500 hover:text-blue-700 mt-2 inline-block">Read More</Link>
              </div>
              <p className="text-gray-700 text-sm m-4">{Date(post?.attributes?.createdAt)}</p>
            </div>
            </Link>
        ))}
      </ul>
    </div>
  );
};

export default BlogPost;
