import * as React from "react";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";
import AuthModal from "../../components/AuthModal";
import Footer from "../../components/Footer";
import TakeTestButton from "../../components/TakeTestButton";

const QuadrantInfo = ({ text }) => (
    <div className="text-lg font-medium text-white">{text}</div>
);

const CognitiveFunction = ({ title, description }) => (
    <div className="flex flex-col text-white">
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-lg font-medium">{description}</p>
    </div>
);

const SeasonCard = ({ season, icon, description }) => (
    <div className="flex overflow-hidden relative flex-col grow items-center px-5 pt-5 pb-14 mt-3.5 text-white aspect-[0.69] fill-white fill-opacity-10 stroke-[1px] stroke-cyan-400 max-md:pl-5 max-md:mt-10">
        <img loading="lazy" src={icon} alt={`${season} icon`} className="aspect-[1.03] w-[94px]" />
        <h3 className="relative mt-4 text-3xl">{season}</h3>
        <p className="relative self-stretch mt-4 text-base">{description}</p>
    </div>
);


function ContentComponent() {
    return (
        <main className="text-xl font-bold  text-white justify-center items-center w-3/4 mx-auto ">
            <h1 className="text-center relative mt-20 text-3xl max-md:mt-10 max-md:max-w-full">
                The TypeScience Personality
            </h1>
            <h4 className="text-center font-grifter text-4xl md:text-45xl mt-16 md:mt-16">Framework</h4>
            <p className="relative mt-14 mb-11 text-md font-medium mx-32">
                Discover the dimensions of TypeScience personality psychology based on the{" "}
                <a href="https://objectivepersonalitysystem.com/" target="_blank" className="text-blue-500 underline w-3/4">Objective Personality System (OPS)</a>. This page offers a clear and comprehensive breakdown of the key personality traits, making the complexities of human behavior accessible and practical.
            </p>
            <img
                className="z-0 absolute animate-float fade-image -translate-y-80"
                srcSet="bright.svg"
            ></img>
        </main>
    );
}

function MyComponent() {
    return (
        <div className="flex flex-col pb-16 bg-slate-950">
            <AuthModal />
            <Header active="framework"></Header>
            <Helmet>
                <meta charSet="utf-8" />
                <title>TypeScience - Framework</title>
                <meta name="description" content="Framework" />
                <meta property="og:type" content="article" />
                <body className="root" />

            </Helmet>
            <ContentComponent></ContentComponent>
            <header className="flex flex-col w-full text-white max-md:max-w-full">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1701c0075202956f39faf124aba47daa7578c4f91c485059e8d75ed6f0dbfb74?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="Background image" className="object-cover absolute inset-0 w-full -translate-y-24" />
            </header>

            <main className=" my-56 w-3/4 mx-auto">
                <section className="flex gap-5 items-start mt-12 max-md:flex-wrap max-md:pr-5 max-md:mt-10">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f07d68f1cba3f55ad4abe7e78a13aefc7bed64bb97433062064a4b3b7dbf9817?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="Personality types illustration" className="shrink-0 max-w-full aspect-[0.95] w-[321px]" />
                    <div className="flex flex-col mt-11 max-md:mt-10 max-md:max-w-full">
                        <h1 className="text-6xl font-bold text-white">
                            Personality Types
                        </h1>
                        <p className="align-center my-auto mt-8 text-2xl text-white max-md:max-w-full">
                            There are 32 main personality archetypes in total. If you're familiar with Myers Briggs (MBTI), this includes the original 16 types, as well as a variant of each type which makes up 32 types. Within each type, the test takes you even further with additional components which vary, resulting in 384 archetypes.
                        </p>
                    </div>
                </section>

                <section className="flex z-10 flex-col self-center px-5 mt-4 w-full max-md:max-w-full">
                    <h3 className="justify-center text-xl font-black leading-6 text-violet-700 max-md:max-w-full">
                        Personality Quadrants
                    </h3>
                    <h2 className="mt-10 text-5xl font-bold leading-10 text-white max-md:max-w-full max-md:text-4xl">
                        Quadrants
                    </h2>
                    <p className="mt-4 text-xl font-medium leading-6 text-white max-md:max-w-full">
                        The quadrants specify four groups of Personality Types according to preferred strategies they use to meet their needs. Everyone has a bit of all four quadrants, but only one is a dominant one that will dictate your personality the most.
                    </p>
                    <div className="text-sm text-white rounded-sm p-10 my-5 relative">
                        <img
                            className="hidden xl:flex -translate-y-64 absolute w-[1400px] h-[1400px] z-0 t-0 r-0 fade-image"
                            srcSet="bright.svg"
                        ></img>
                        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
                            <div className="col-span-6   rounded-lg p-2 md:p-5 border-[#7035DE] bg-[#7035DE] bg-opacity-15">
                                <img className=" mx-auto mb-4" src="Gatherers.png"></img>
                                <p className="text-mid my-auto px-1 leading-normal md:px-6 pb-4 text-center md:leading-relaxed">  Prefer acquiring new information, whether Concrete or Abstract (depending on their Type), before consulting existing knowledge, prioritizing novelty and exploration.</p>
                            </div>
                            <div className="col-span-6  border-[#7035DE] bg-[#7035DE] bg-opacity-15 rounded-lg p-2 md:p-5">
                                <img className=" mx-auto mb-4" src="Organizers.png"></img>
                                <p className="text-mid my-auto px-6 pb-4 text-center leading-relaxed">Refer to existing Concrete or Abstract information (depending on their Type) before seeking new alternatives, prioritizing structure and orderliness in decision-making.</p>
                            </div>
                            <div className="col-span-6 border-[#7035DE] bg-[#7035DE] bg-opacity-15 rounded-lg p-2 md:p-5">
                                <img className=" mx-auto mb-4" src="Indivdualists.png"></img>
                                <p className="text-mid my-auto px-6 pb-4 text-center leading-relaxed"> Tend to prioritize their own inner thoughts and feelings (Heart or Mind, depending on their Type) over those of the group, emphasizing personal perspectives.</p>
                            </div>
                            <div className="col-span-6 border-[#7035DE] bg-[#7035DE] bg-opacity-15 rounded-lg  p-2 md:p-5">
                                <img className=" mx-auto mb-4" src="Collaborators.png"></img>
                                <p className="text-mid my-auto px-6 pb-4 text-center leading-relaxed">Primarily consider a broader range of collective thoughts and feelings (Heart or Mind, depending on their Type) over their individual ones, valuing group perspectives..</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="flex z-10 flex-col self-center mt-0 w-full text-white max-md:mt-0 max-md:max-w-full">
                    <div className="relative flex z-10 flex-col mt-0 max-md:max-w-full">
                        <h3 className="mt-16 text-xl font-black leading-6 text-violet-700 max-md:mt-10 max-md:max-w-full">
                            Personality Functions
                        </h3>
                        <h2 className="mt-4 text-5xl font-bold leading-10 max-md:max-w-full max-md:text-4xl">
                            Cognitive Functions
                        </h2>
                        <p className="mt-4 text-xl font-medium leading-6 text-white max-md:max-w-full">
                            Each of the 32 Personality Types is defined by the two Cognitive Functions, or mental tools, they prefer to use to understand and respond to the world.
                            <br /><br />
                            Each of these can have a Deep focus, which is more narrow and considers a small set of criteria more deeply, or a Broad focus, which considers a wider range of information and perspectives, but with less depth.
                        </p>
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bdf3911fac3c6eceae98d3513d3e8696f5fbb191c5d762a98c5d83e8a16e3646?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="Cognitive functions diagram" className="absolute self-center text-center bg-center top-1/2 grow mt-5 w-1/4 max-md:max-w-full" />
                        <div className="text-sm text-white rounded-sm p-10 my-5">
                            <img
                                className="hidden xl:flex -translate-y-64 absolute w-[1400px] h-[1400px] z-0 t-0 r-0 fade-image"
                                srcSet="bright.svg"
                            ></img>
                            <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
                                <div className="col-span-6  rounded-lg p-2 md:p-5 border-[#7035DE] bg-[#7035DE] bg-opacity-15">
                                    <img className=" mx-auto mb-4" src="HEART-concret.png"></img>
                                    <p className="text-mid my-auto px-1 leading-normal md:px-6 pb-4 text-center md:leading-relaxed">  Pertaining to tangible, observable, and factual information that is grounded in reality. Concrete information is often related to sensory experiences and practical details. Called Sensing in Jungian Typology.</p>
                                </div>
                                <div className="col-span-6 rounded-lg p-2 md:p-5 border-[#7035DE] bg-[#7035DE] bg-opacity-15">
                                    <img className=" mx-auto mb-4" src="HEART-heart.png"></img>
                                    <p className="text-mid my-auto px-6 pb-4 text-center leading-relaxed">Involving emotional processes, values, subjective feelings, and empathy. This can involve a preference for empathizing with others, considering personal values, and making decisions based on emotional resonance. Called Feeling in Jungian Typology.</p>
                                </div>
                                <div className="col-span-6 rounded-lg p-2 md:p-5 border-[#7035DE] bg-[#7035DE] bg-opacity-15">
                                    <img className=" mx-auto mb-4" src="HEART-mind.png"></img>
                                    <p className="text-mid my-auto px-6 pb-4 text-center leading-relaxed"> Involving cognitive processes related to logic, analysis, rationality, and objective thinking with a focus on logical reasoning, problem-solving, and critical analysis when making decisions. This can involve a preference for thinking about facts, figures, and objective information. Called Thinking in Jungian Typology.</p>
                                </div>
                                <div className="col-span-6 rounded-lg  p-2 md:p-5 border-[#7035DE] bg-[#7035DE] bg-opacity-15">
                                    <img className=" mx-auto mb-4" src="HEART-abstract.png"></img>
                                    <p className="text-mid my-auto px-6 pb-4 text-center leading-relaxed">Involving conceptual, theoretical, and non-tangible ideas that are not necessarily tied to specific sensory experiences. Abstract information is more focused on concepts, theories, and principles rather than immediate sensory input. Called iNtuition in Jungian Typology.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="mt-28 ml-10 max-md:mt-10 max-md:max-w-full">
                    <h3 className="text-xl font-black leading-6 text-violet-700">Personality Prescriptions</h3>
                    <h2 className="mt-4 text-5xl font-bold leading-10 text-white max-md:max-w-full max-md:text-4xl">Prescriptions</h2>
                    <p className="mt-11 text-xl font-medium leading-6 text-white max-md:mt-10 max-md:max-w-full">
                        The prescriptions are areas of life where you can enhance your well-being through specific actions and behaviors, determined by Cognitive functions working together. These prescriptions correspond to seasonal themes, reflecting behaviors commonly observed during different times of the year.
                    </p>
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6d0321f329fec883b8695e89ff815a389b7ec88069fecf826985215872d7eae?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="" className="mt-12 aspect-square fill-cyan-400 w-[19px] max-md:mt-10" />
                    <div className="grid grid-cols-1 lg:grid-cols-12 gap-x-12 text-white">
                        <div className="col-span-3 my-5  bg-opacity-25 bg-[#5f5373] rounded-lg p-2 md:p-5">
                            <img className="w-24 mx-auto mb-4" src="https://cms.typescience.ai/uploads/spring_icon_6e08bd54cf.png"></img>
                            <p className="text-4xl text-center mb-4">Spring </p>
                            <p className="text-mid my-auto px-1 pb-4 text-center ">   A season for exploration and learning, where the world undergoes renewal. To benefit, engage in activities such as reading, researching, and observing to acquire new knowledge and update your perspectives in alignment with the unfolding cycle.</p>
                        </div>
                        <div className="col-span-3 my-5  bg-opacity-25 bg-[#5f5373] rounded-lg p-2 md:p-5">
                            <img className="w-24 mx-auto mb-4" src="https://cms.typescience.ai/uploads/summer_icon_7b9bcb1c9d.png"></img>
                            <p className="text-4xl  text-center mb-4">Summer </p>
                            <p className="text-mid my-auto px-1 pb-4 text-center">A time of vibrant social interaction and practical application of insights amid favorable weather conditions. Embrace opportunities for engagement and play with others and the environment, such as conducting interviews and collaborating on projects, to deepen understanding and integrate learning.</p>
                        </div>
                        <div className="col-span-3 my-5  bg-opacity-25 bg-[#5f5373] rounded-lg p-2 md:p-5">
                            <img className="w-24 mx-auto mb-4" src="https://cms.typescience.ai/uploads/fall_icon_c4196a511f.png"></img>
                            <p className="text-4xl  text-center mb-4">Fall </p>
                            <p className="text-mid my-auto px-1 pb-4 text-center"> A period of productivity and implementation, characterized by reaping the rewards of earlier efforts before the onset of winter. Maximize benefits by applying existing knowledge and skills in productive endeavors, taking action to initiate projects and diligently work towards achieving goals.</p>
                        </div>
                        <div className="col-span-3 my-5  bg-opacity-25 bg-[#5f5373] rounded-lg  p-2 md:p-5">
                            <img className="w-24 mx-auto mb-4" src="https://cms.typescience.ai/uploads/winter_icon_0992931482.png"></img>
                            <p className="text-4xl text-center mb-4">Winter </p>
                            <p className="text-mid my-auto px-1 pb-4 text-center">A season for introspection and conservation of energy, as external conditions become less conducive to activity. To thrive, prioritize activities such as resting, reflecting, and engaging in introspective practices, allowing for the preservation of mental and emotional resources and the resolution of complex challenges.</p>
                        </div>
                    </div>
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6d0321f329fec883b8695e89ff815a389b7ec88069fecf826985215872d7eae?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="" className="self-center mt-12 aspect-square fill-cyan-400 w-[19px] max-md:mt-10" />
                </section>

                <section className="mt-10 w-full ">
                    <div className="grid grid-cols-1 lg:grid-cols-12 bg-opacity-25 bg-[#7035DE] text-white">
                        <div className="relative col-span-6 my-5 p-2 md:p-5">
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6d0321f329fec883b8695e89ff815a389b7ec88069fecf826985215872d7eae?apiKey=2d1d137607f749e6b268ecb1ba4f2080&" alt="" className="mt-12 aspect-square fill-cyan-400 w-[19px] max-md:mt-10" />

                            <img
                                className="hidden xl:flex -translate-y-64 absolute w-[1400px] h-[1400px] z-0 t-0 r-0 fade-image"
                                srcSet="bright.svg"
                            ></img>
                            <p className="text-4xl text-center mb-4 font-grifter">Personality Traits </p>
                            <p className="text-mid my-auto px-1 pb-4 ">
                                The Objective Personality System has many parts to it which show you what your dominant and recessive traits are across various axes.
                            </p>
                            <p className="text-mid my-auto px-1 pb-4 ">
                                Unlocking Premium Test Results will provide greater depth by revealing your scores on each of these traits, accompanied by confidence ratings for each assessment.
                            </p>
                            <p className="text-mid my-auto px-1 pb-4 ">
                                This comprehensive analysis aids in identifying areas of strong alignment as well as potential misinterpretations, empowering you to refine your understanding of your personality type.
                            </p>
                        </div>
                        <div className="col-span-6 my-5 p-2 md:p-5">
                            <img className="max-h-96 mx-auto mb-4" src="coinsx.png"></img>
                        </div>
                    </div>
                </section>
                <div className="mt-16">
                    <TakeTestButton></TakeTestButton>
                    <p className="text-center text-mid text-white my-5"> Try it free, no email required.</p>
                </div>

                <section className="z-10 mt-0 w-full max-md:max-w-full">

                    <h3 className="text-white bg-clip-text leading-[120%] max-md:mt-10 max-md:max-w-full">Subtypes Coming soon</h3>
                    <h2 className=" text-5xl font-bold leading-10 text-white max-md:max-w-full max-md:text-4xl">Subtypes</h2>
                    <p className="mt-11 text-lg leading-6 text-white max-md:mt-10 max-md:max-w-full">
                        The prescriptions are areas of life where you can enhance your well-being through specific actions and behaviors, determined by Cognitive functions working together. These prescriptions correspond to seasonal themes, reflecting behaviors commonly observed during different times of the year.
                    </p>
                </section>
            </main>

            <Footer></Footer>
        </div>
    );
}

export default MyComponent;