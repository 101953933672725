import React from 'react';
import ReactMarkdown from 'react-markdown';
import Header from "../../components/Header";
import * as Style from '../Home/styles'
import AuthModal from "../../components/AuthModal";
import Footer from '../../components/Footer';

const termsText = `
# Terms and Conditions

**Welcome to Typescience Inc.!**

These terms and conditions outline the rules and regulations for the use of Typescience Inc.'s Website, located at www.typescience.ai.

By accessing this website we assume you accept these terms and conditions. Do not continue to use Typescience Inc. if you do not agree to take all of the terms and conditions stated on this page.

# License

Unless otherwise stated, Typescience Inc. and/or its licensors own the intellectual property rights for all material on Typescience Inc. All intellectual property rights are reserved. You may access this from Typescience Inc. for your own personal use subjected to restrictions set in these terms and conditions.

# User Content

In these terms and conditions, "Your Content" shall mean any audio, video text, images, or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Typescience Inc. a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.

# Disclaimer

The information provided by Typescience Inc. ('we,' 'us' or 'our') on www.typescience.ai (the 'Site') is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.

`;

const CustomHeader = ({ children }) => (
    <h1 className="text-2xl font-bold text-gray-50 my-4">{children}</h1>
);

const CustomSubHeader = ({ children }) => (
    <h2 className="text-xl font-semibold text-gray-800 my-3">{children}</h2>
);
const CustomParagraph = ({ children }) => (
    <p className="text-gray-600 my-2">{children}</p>
);

const CustomList = ({ children }) => (
    <ul className="list-disc list-inside space-y-1 text-gray-600">{children}</ul>
);

const CustomListItem = ({ children }) => (
    <li className="pl-4">{children}</li>
);


const TermsConditions = () => {
    const markdownComponents = {
        h1: CustomHeader,
        h2: CustomSubHeader,
        p: CustomParagraph,
        ul: CustomList,
        li: CustomListItem,
    }
    return (
        <div className="max-w-4xl mx-auto p-6 text-teal-50 shadow-md rounded">
            <ReactMarkdown components={markdownComponents} className="prose">
                {termsText}
            </ReactMarkdown>
        </div>
    );
};

const Terms = () => {

    return (
        <Style.Div>
            <AuthModal />
            <Header></Header>
            <TermsConditions></TermsConditions>
            <Footer></Footer>
        </Style.Div>
    )
}

export default Terms;
