import React, { useEffect, useState } from 'react';
import { FaCopy, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import axios from 'axios';


const ShareResultComponent = ({ test, personlaity_name, personality_mbti, withSend = true, withPublicLink = true }) => {
    const [testDetail, setTestDetail] = useState({});
    const [email, setEmail] = useState('');
    const [copiedTest, setCopiedTest] = useState();
    const [showTooltip, setShowTooltip] = useState(false);
    const [linkModified, setLinkModified] = useState("")

    function replaceSpacesWithHyphens(str) {
        return str?.replace(/\s/g, '-');
    }

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(`https://www.typescience.ai/personality-types/${linkModified}`);
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000);
            setCopiedTest(text);
        } catch (err) {
            setCopiedTest("");
        }
    };

    const fetchTest = async () => {
        try {
            const response = await axios.get(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF:v1/test/${test?.id}`);
            setTestDetail(response.data);
        } catch (error) {
            console.error('There was an error fetching the data:', error);
        }
    };
    const handleShareViaEmail = async () => {
        try {
            const response = await axios.post(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF/test/${test.id}/share`, {
                email,
            });
            fetchTest()
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleDeleteShareForEmail = async (email) => {
        try {
            const response = await axios.post(`https://xbvh-ofc9-nsmy.n7c.xano.io/api:PW99k_MF/test/${test.id}/unshare`, {
                email,
            });
            fetchTest()
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        const linktemp = personlaity_name + "-" + replaceSpacesWithHyphens(personality_mbti)
        setLinkModified(linktemp)
        fetchTest();
    }, [personlaity_name, personality_mbti]);
    return (
        <div>
            {withPublicLink ?
                <div className="my-6 border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-4 text-white block">

                    <h2 className="text-sm text-gray-500 font-bold">Share your personality type!</h2>
                    <p className="text-xs my-2">Let others get a sense of your personality by sharing your tests.</p>
                    <div className="w-1/4 grid grid-cols-3 my-4">
                        <div className="col-span-1"><FaFacebook></FaFacebook></div>
                        <div className="col-span-1"><FaInstagram></FaInstagram></div>
                        <div className="col-span-1"><FaTwitter></FaTwitter></div>
                    </div>
                    <p className="text-xs">Or share the Public link</p>
                    <div onClick={() => handleCopy(linkModified)} className="flex relative gap-2 border border-[#493A65] p-2 my-2 rounded-md items-center" >
                        {showTooltip && (
                            <div className="absolute bg-black text-white text-xs rounded py-1 px-2 right-0 bottom-full mb-2">
                                Copied Share Link!
                            </div>
                        )}
                        <FaCopy></FaCopy>
                        <p className="text-2xs overflow-clip">{`https://www.typescience.ai/personality-types/${linkModified}`}</p>
                    </div>
                </div> : ""}
            {withSend ?
                <div className="my-6 border border-[#493A65] bg-[#493A65] bg-opacity-20 rounded-lg p-4 text-white">
                    <h2 className="text-sm text-gray-500 font-poppins">Collaborative Insights</h2>

                    <p className="text-2xs my-2">Enter an email below to share your full results on someone else's Dashboard. This option is ideal for sharing your full results, or for tests taken on behalf of someone else to help them gain a more accurate understanding of their personality.</p>
                    <div className='grid grid-cols-12 gap-2'>
                        <input className="col-span-10 border border-[#493A65] bg-opacity-20 p-2 my-2 rounded-md items-center bg-[#493A65]"
                            placeholder='Email'
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        >
                        </input>
                        <button onClick={handleShareViaEmail} className='col-span-2 bg-[#493A65] rounded-lg my-2 text-xs'> Send </button>
                    </div>
                    {testDetail?.shared_with?.map(shared_with => (
                        <div key={shared_with} className='grid grid-cols-12 gap-2'>
                            <p className="col-span-11 border border-[#493A65] bg-opacity-20 p-2 my-2 rounded-md items-center bg-[#493A65]"  >
                                {shared_with}
                            </p>
                            <button onClick={() => handleDeleteShareForEmail(shared_with)} className='col-span-1 bg-[#493A65] rounded-lg my-2'> x </button>
                        </div>
                    ))}
                </div> : ""}
        </div>
    );
}

export default ShareResultComponent;
